import { CollectorModel } from '@admin/modules/collectors/model/collectors.model';
import { FormInputBase } from '../components/dynamic-form/models/form-input-base';

/**
 * Updates the options of an input with the collectors allowed for the user.
 *
 * @param {string[]} userPermissions - Array of user permissions.
 * @param {string[]} userAllowsIds - Array of allowed collector IDs for the user.
 * @param {CollectorModel[]} collectors - Complete array of collectors.
 * @param {FormInputBase<string | boolean>[]} input - Array of inputs to update.
 * @param {number} inputIndex - Index of the input to update.
 */
export const UserAllowsCollectorsID = (
  userPermissions: string[],
  userAllowsIds: string[],
  collectors: CollectorModel[],
  input: FormInputBase<string | boolean>[],
  inputIndex: number,
) => {
  const allowedCollectors = userPermissions.includes('full_access')
    ? collectors
    : collectors.filter((collector) => userAllowsIds.includes(collector.id));
  const resIds = allowedCollectors.map(({ name, id }) => ({
    label: name,
    value: id,
  }));

  input[inputIndex].setOptions(resIds);
};

/**
 * Return the collectors allowed for the user.
 *
 * @param {string[]} userPermissions - Array of user permissions.
 * @param {string[]} userAllowsIds - Array of allowed collector IDs for the user.
 * @param {CollectorModel[]} collectors - Complete array of collectors.
 */
export const getUserAllowsCollectors = (
  userPermissions: string[],
  userAllowsIds: string[],
  collectors: CollectorModel[],
) => {
  return userPermissions.includes('full_access')
    ? collectors
    : collectors.filter((collector) => userAllowsIds.includes(collector.id));
};
