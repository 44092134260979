import { Component, OnInit, Input, OnChanges, inject, SimpleChanges } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable, catchError, finalize, map, of, switchMap } from 'rxjs';
import { GET_ACTIVITY_INFO } from './graphql/activity-sidebar.GraphQL';
import { CurrentUserService } from '@core/services/currentUser.service';
import { CustomerService } from '@modules/customers/customer.service';
import { Subject, takeUntil } from 'rxjs';
import { CurrencyWithCodePipe } from '../../pipes/currency-with-code.pipe';
import {
  AllowedActivities,
  activityFilters,
  customerActivity,
} from '@core/models/customers/customerJourney.model';
import { ApolloQueryResult } from '@apollo/client';
import { GraphQLError } from 'graphql';
import { TenantService } from '@modules/tenants/services/tenant.service';
import { TranslateService } from '@ngx-translate/core';

type ResponseType = {
  activities: customerActivity[];

  errors: {
    message: string;
  }[];
};

@Component({
  selector: 'app-activities',
  templateUrl: './activities.component.html',
  styleUrls: ['./activities.component.scss'],
})
export class ActivitiesComponent implements OnInit, OnChanges {
  @Input() filter?: AllowedActivities;
  counter: number = 1;
  noMoreResults = false;
  private unsubscribe$ = new Subject<void>();

  activity: customerActivity[] = [];
  loading = false;
  tenant = { id: '', name: '' };
  currency = 'EUR'; //TODO: FROM BACKEND CURRENCY
  apollo = inject(Apollo);
  customerService = inject(CustomerService);
  currentUser = inject(CurrentUserService);
  tenantService = inject(TenantService);
  currencyWithCode = inject(CurrencyWithCodePipe);
  translate = inject(TranslateService);

  constructor() {
    this.tenantService.getActualTenantCollectors().subscribe((tenant: string) => {
      this.tenant = { ...this.tenant, name: tenant };
      if (this.tenant.name === 'UNIR' || this.tenant.name === '01H4DA89TRN2K17B2XT3J8ZZCV') {
        this.currency = 'MXN'; //TODO: FROM BACKEND CURRENCY
      }
    });
  }
  ngOnInit(): void {
    this.searchActivities()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: customerActivity[]) => {
        this.activity = res;
      });
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['filter']) {
      this.counter = 1;
      this.unsubscribe$.next();
      this.searchActivities(this.filter)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((res) => (this.activity = res));
    }
  }

  increase() {
    this.counter++;
    this.searchWithDebounce();
  }

  searchWithDebounce() {
    this.searchActivities(this.filter)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res) => (this.activity = res));
  }

  searchActivities(activities?: AllowedActivities): Observable<customerActivity[]> {
    this.loading = true;

    return this.apollo
      .query<ResponseType>({
        query: GET_ACTIVITY_INFO,
        variables: {
          criteria: {
            pagination: {
              offset: 10 * (this.counter - 1),
              limit: 10,
            },
            filters: {
              tenantId: this.currentUser.currentUser.tenantId,
              collectorIds: this.currentUser.currentUser.collectorIds,
              activities: activities || activityFilters.PAYLINK_NOTIFICATION_SENT,
            },
          },
        },
      })
      .pipe(
        map((res: ApolloQueryResult<ResponseType>) => {
          if (res.errors?.length) {
            throw new Error(res.errors[0].message);
          } else {
            return res.data.activities;
          }
        }),
        catchError((error: GraphQLError | undefined) => {
          throw error;
        }),
        finalize(() => {
          this.loading = false;
          // Verifica si no hay más resultados y actualiza noMoreResults.
          if (this.activity.length % 10 !== 0) {
            this.noMoreResults = true;
          }
        }),
        // Concatenar los nuevos resultados a la lista existente.
        switchMap((newResults: customerActivity[]) => {
          if (this.counter === 1) {
            this.activity = [];
            this.noMoreResults = false;
          }
          this.activity = this.activity.concat(newResults);
          return of(this.activity);
        }),
      );
  }

  // ACTIVITY SIDEBAR
  handleNotificationEvent(event: any): string {
    let copy: string;

    const customerName = event.parameters.customer.longName;
    const externalId = event.parameters.customer.externalId;
    const causationType = event.parameters?.causation?.type;
    const causationName = event.parameters?.causation?.name;

    switch (causationType) {
      case 'api':
        copy = this.translate.instant('DATA.MODULES.LAYOUT.ACTIVITY.MESSAGES.API', {
          customerName,
          externalId,
          source: this.getName(causationType),
        });
        break;
      case 'campaign':
        copy = this.translate.instant('DATA.MODULES.LAYOUT.ACTIVITY.MESSAGES.CAMPAIGN', {
          customerName,
          externalId,
          source: this.getName(causationType),
        });
        break;
      case 'payment_agreement':
        copy = this.translate.instant('DATA.MODULES.LAYOUT.ACTIVITY.MESSAGES.PAYMENT_AGREEMENT', {
          customerName,
          externalId,
        });
        break;
      case 'reminder':
        copy = this.translate.instant('DATA.MODULES.LAYOUT.ACTIVITY.MESSAGES.REMINDER', {
          customerName,
          externalId,
        });
        break;
      default:
        copy = this.translate.instant('DATA.MODULES.LAYOUT.ACTIVITY.MESSAGES.DEFAULT', {
          customerName,
          externalId,
        });
        break;
    }

    if (causationName) {
      copy += ` - ${causationName}`;
    }

    return copy;
  }

  handlePaymentEvent(event: any): string {
    const customerName = event.parameters.customer.longName;
    const externalId = event.parameters.customer.externalId;
    const causationType = event.parameters?.causation?.type;
    const causationName = event.parameters?.causation?.name;
    const paymentAmount = event.parameters.paymentOrder.amount;
    let copy = this.translate.instant('DATA.MODULES.LAYOUT.ACTIVITY.MESSAGES.PAYMENT_RECEIVED', {
      amount: this.currencyWithCode.transform(paymentAmount, this.currency),
      customerName: customerName,
      externalId: externalId,
    });

    if (causationType) {
      copy += ` ${this.translate.instant('DATA.MODULES.LAYOUT.ACTIVITY.MESSAGES.FROM', { source: this.getName(causationType) })}`;
    }
    if (causationName) {
      copy += ` - ${causationName}`;
    }

    return copy;
  }

  handleFeedbackEvent(event: any): string {
    const customerName = event.parameters.customer.longName;
    const externalId = event.parameters.customer.externalId;
    const causationType = event.parameters?.causation?.type;
    const causationName = event.parameters?.causation?.name;

    let copy = this.translate.instant('DATA.MODULES.LAYOUT.ACTIVITY.MESSAGES.FEEDBACK', {
      customerName,
      externalId,
    });

    if (causationType) {
      copy += ` ${this.translate.instant('DATA.MODULES.LAYOUT.ACTIVITY.MESSAGES.FROM', {
        source: this.getName(causationType),
      })}`;
    }

    if (causationName) {
      copy += ` - ${causationName}`;
    }

    return copy;
  }

  //Utils
  getColor(category: string, eventName: string): string {
    return this.customerService.getColor(category, eventName);
  }

  getIcon(category: string, eventName: string): string {
    return this.customerService.getIcon(category, eventName);
  }

  getName(eventName: string): string {
    return this.customerService.getName(eventName);
  }

  getIconColor(category: string, eventName: string): string {
    return this.customerService.getIconColor(category, eventName);
  }
}
