import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ConfirmationService } from 'primeng/api';
import { Subscription, debounceTime } from 'rxjs';
import { FormInputBase } from './models/form-input-base';

@Component({
  selector: 'app-dynamic-form',
  templateUrl: './dynamic-form.component.html',
})
export class DynamicFormComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {
  @Input() formFields: FormInputBase<any>[] = [];
  @Input() submit = true;
  @Input() reset = true;
  @Input() cancel = false;
  @Input() confirm = false;
  @Input() btnLabel = 'Enviar';
  @Input() btnLabel2 = 'Cancelar';
  @Input() btnCancel = 'DATA.COMMON.ACTIONS.CANCEL';
  @Input() btnLabel3 = 'Editar';
  @Input() btnLabelSave = 'Guardar';
  @Input() btnLabelReset = 'Cancelar';
  @Input() confirmMsg = '¿Seguro que deseas guradar los cambios?';
  @Input() btnMargin = '16px';
  @Input() resetAfterSubmit = false;
  @Input() formCols = 3;
  @Input() isDisabled = true;
  @Input() enterSendValue = true;
  @Input() suprDeleteForm = true;

  @Output() formData = new EventEmitter();
  @Output() cancelForm = new EventEmitter();
  @Output() resetForm = new EventEmitter();
  @Output() formValuesChanged: EventEmitter<any> = new EventEmitter<any>();

  form!: FormGroup;
  formChangesSubscription?: Subscription;
  isEditMode: boolean = false;
  constructor(private confirmService: ConfirmationService) {}

  ngOnInit(): void {
    this.toFormGroup();
  }

  ngAfterViewInit(): void {
    this.subscribeToFormChanges();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['formFields']) {
      this.toFormGroup();
      this.subscribeToFormChanges(); // Ensure subscription is set up
    }
  }

  private toFormGroup(): void {
    const group = {} as any;
    this.formFields!.forEach((field) => {
      group[field.key] = new FormControl(field.value || '', field.validators);
    });
    this.form = new FormGroup(group);
  }

  private subscribeToFormChanges() {
    if (this.formChangesSubscription) {
      this.formChangesSubscription.unsubscribe();
    }
    this.formChangesSubscription = this.form.statusChanges
      .pipe(debounceTime(100))
      .subscribe((status: string) => {
        if (status === 'VALID' || status === 'INVALID') {
          this.formValuesChanged.emit(this.form.value);
        }
      });
  }

  hasFormUnsavedChanges(): boolean {
    return this.form && this.form.dirty;
  }

  send(): void {
    this.form.markAllAsTouched();
    if (this.form.invalid) {
      return;
    }
    this.formData.emit(this.form.value);
    if (this.resetAfterSubmit) {
      this.form.reset();
    }
  }

  setPlaceholders(event: { partial: string; field: FormInputBase<any>; cursorPosition: number }) {
    const currentValue = this.form.get(event.field.key)!.value;
    const textBeforeCursor = currentValue.substring(0, event.cursorPosition);
    const textAfterCursor = currentValue.substring(event.cursorPosition);
    const newValue = textBeforeCursor + event.partial + textAfterCursor;
    this.form.get(event.field.key)!.setValue(newValue);
  }

  setChip(event: { event: any; field: FormInputBase<any> }) {
    const currentValue = this.form.get(event.field.key)!.value || [];
    const newValue = currentValue.filter((value: any) => value !== event.event.value);
    this.form.get(event.field.key)!.setValue(newValue);
    //Hack to update the input value
    document.getElementById(event.field.key)!.getElementsByTagName('input')[0].value =
      event.event.value;
  }

  sendAndEdit(): void {
    this.form.markAllAsTouched();
    if (this.form.invalid) {
      this.isEditMode = false;
      return;
    }
    this.formData.emit(this.form.value);
    this.isEditMode = false;
    FormInputBase.setReadonly(this.formFields, true);
    FormInputBase.setDisabled(this.formFields, true);
  }

  clean() {
    this.form.reset({});
    this.resetForm.emit(true);
  }

  onCancel = () => {
    this.cancelForm.emit();
  };

  editMode() {
    this.isEditMode = true;
    // this.formData.emit({ edit: false });
    FormInputBase.setReadonly(this.formFields, false);
    FormInputBase.setDisabled(this.formFields, false);
  }

  openConfirmationModal(event: Event) {
    this.confirmService.confirm({
      key: 'save-pop',
      acceptLabel: 'Aceptar',
      rejectLabel: 'Cancelar',
      // acceptVisible: this.form.status === 'VALID',
      target: event.target || new EventTarget(),
      message: this.form.status === 'VALID' ? this.confirmMsg : 'Hay errores en el formulario',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.form.markAllAsTouched();
        if (this.form.invalid) {
          this.isEditMode = false;
          return;
        }
        this.isEditMode = false;
        FormInputBase.setReadonly(this.formFields, true);
        FormInputBase.setDisabled(this.formFields, true);
        this.formData.emit({
          edit: true,
          data: this.form.value,
        });
      },
      reject: () => {
        this.toFormGroup(); //RESTART INITIAL STATE AND ERASE VALUE CHANGES
        this.isEditMode = false;
        FormInputBase.setReadonly(this.formFields, true);
        FormInputBase.setDisabled(this.formFields, true);
      },
    });
  }

  /**
   * RESTART INITIAL STATE AND ERASE VALUE CHANGES
   **/
  cancelEdit() {
    if (this.form.invalid) {
      this.isEditMode = false;
      return;
    }
    this.toFormGroup(); //RESTART INITIAL STATE AND ERASE VALUE CHANGES
    this.isEditMode = false;
    FormInputBase.setReadonly(this.formFields, true);
    FormInputBase.setDisabled(this.formFields, true);
  }

  ngOnDestroy(): void {
    if (this.formChangesSubscription) {
      this.formChangesSubscription.unsubscribe();
    }
  }
}
