import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { searchInputs } from './config/searchForm';
import { FormTextbox } from '@shared/components/dynamic-form/models';
import { TranslateService } from '@ngx-translate/core';
searchInputs;
@Component({
  selector: 'app-simple-search',
  templateUrl: './simple-search.component.html',
})
export class SimpleSearchComponent implements OnInit {
  @Output() result: EventEmitter<any> = new EventEmitter<any>();
  searchInputs: FormTextbox[] = [];

  constructor(private translate: TranslateService) {}

  ngOnInit() {
    this.searchInputs = this.getInputs();
  }

  search(event: any) {
    this.result.emit(event['search']);
  }

  getInputs() {
    return [
      new FormTextbox({
        key: 'search',
        type: 'textbox',
        required: false,
        label: this.translate.instant('DATA.ADMIN.MODULES.PORTFOLIOS.FORM.SEARCH'),
        value: '',
        placeholder: '',
        readonly: false,
      }),
    ];
  }
}
