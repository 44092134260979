import { Injectable } from '@angular/core';
import * as joint from 'jointjs';
import {
  ConditionalNode,
  ConditionalSegmentNode,
  ExperimentNode,
  MultiportsNode,
} from '../config/extended.shapes';
import { ulid } from 'ulid';
import { draggableMenu } from '@shared/components/joint-js/config/constants';
import { TranslateService } from '@ngx-translate/core';

const baseLabelOpts = {
  ref: 'body',
  yAlignment: 'middle',
  xAlignment: 'middle',
  fontSize: 10,
  fontFamily: 'Helvetica',
  fontWeight: 'bold',
};

@Injectable({
  providedIn: 'root',
})
export class CreateNewNodesService {
  constructor(private translate: TranslateService) {}

  createNode(type: string, x: number, y: number, data?: any): joint.dia.Element | null {
    switch (type) {
      case 'start':
        return this.startNode(x, y, data);
      case 'waitDayOfMonth':
        return this.waitMonth(x, y, data);
      case 'waitDays':
        return this.waitDays(x, y, data);
      case 'waitDaysForDueDate':
        return this.waitDueDate(x, y, data);
      case 'waitDayOfWeek':
        return this.waitDayOfWeek(x, y, data);
      case 'paylinkNotificationStatus':
        return this.ifNode(x, y, data);
      case 'sendSMS':
        if (data && data['copyIds'].length >= 2) {
          return this.superSendSMS(x, y, data);
        }
        return this.sendSMS(x, y, data);
      case 'sendEmail':
        if (data && data['copyIds'].length >= 2) {
          return this.superSendEmail(x, y, data);
        }
        return this.sendEmail(x, y, data);
      case 'sendCertifiedSMS':
        if (data && data['copyIds'].length >= 2) {
          return this.superSendCertifiedSMS(x, y, data);
        }
        return this.sendCertifiedSMS(x, y, data);
      case 'sendCertifiedEmail':
        if (data && data['copyIds'].length >= 2) {
          return this.superSendCertifiedEmail(x, y, data);
        }
        return this.sendCertifiedEmail(x, y, data);
      case 'superSendSMS':
        return this.superSendSMS(x, y, data);
      case 'superSendCertifiedSMS':
        return this.superSendCertifiedSMS(x, y, data);
      case 'superSendEmail':
        return this.superSendEmail(x, y, data);
      case 'superSendCertifiedEmail':
        return this.superSendCertifiedEmail(x, y, data);
      case 'assignCampaign':
        return this.assignNode(x, y, data);
      case 'paylinkNotificationStatus':
        return this.ifNode(x, y, data);
      case 'customerCaseSegments':
        return this.ifSegmentsNode(x, y, data);
      case 'experiment':
        return this.experimentNode(x, y, data);
      case 'end':
        return this.endNode(x, y, data);
      case 'multiconditional':
        return this.multiConditionalNode(x, y, data);
      case 'offerPartialPayment':
        return this.partialPaymentNode(x, y, data);
      case 'offerPaymentAgreement':
        return this.offerPaymentAgreementNode(x, y, data);
      case 'makeOutboundCall':
        return this.makeOutboundCallNode(x, y, data);
      default:
        return this.errorNode(x, y, data);
      //TODO: throw error
    }
  }

  createLink(
    source: joint.dia.Element,
    target: joint.dia.Element,
    sourcePort: string,
    targetPort: string,
    data: any = {
      parentNodeId: '',
      childNodeId: '',
    },
  ): joint.shapes.standard.Link {
    const link = new joint.shapes.standard.Link({
      attrs: {
        line: {
          stroke: '#808080',
          strokeWidth: 1,
          interactive: true,
        },
        wrapper: {
          cursor: 'default',
        },
      },

      source: {
        id: source.id,
        port: sourcePort,
        anchor: {
          name: sourcePort,
          args: {
            dy: 2,
          },
        },
      },
      target: {
        id: target.id,
        port: targetPort,
        anchor: {
          name: targetPort,
          args: {
            dy: -2,
          },
        },
      },
      router: {
        name: 'manhattan',
        args: {
          startDirections: ['bottom'],
          endDirections: ['top'],
        },
      },
    });
    link.set('data', { parentId: '11111111111', childId: '2222222' });
    link.set('data', data);

    return link;
  }

  ifNode(
    x: number,
    y: number,
    data: any = {
      original: ulid(),
      name: 'paylinkNotificationStatus',
      targetNodeId: '',
      statuses: [],
      cases: {},
    },
  ) {
    const newNode = new ConditionalNode();
    newNode.resize(120, 60);
    newNode.position(x, y);
    newNode.attr({
      path: {
        d: 'M 60 0 L 120 30 60 60 0 30 z',
        fill: '#ff851b',
        stroke: 'none',
        filter: { name: 'dropShadow', args: { dx: 2, dy: 2, blur: 3, color: 'rgba(0,0,0,0.2)' } },
      },
      text: {
        text: this.translate.instant(
          `DATA.MODULES.CAMPAINGS.ELEMENTS.NODES.NAMING.paylinkNotificationStatus`,
        ),
        fill: '#ff851b',
        fontSize: 9,
        fontFamily: 'Helvetica',
        fontWeight: 'bold',
      },
    });
    newNode.set('nodeType', 'conditional');
    newNode.set('data', data);

    newNode.addPort({ group: 'top' });
    newNode.addPort({ group: 'bottom' });
    newNode.addPort({ group: 'right' });

    newNode.prop('ports', {
      groups: {
        top: {
          position: {
            name: 'absolute',
            args: { y: 0 },
          },
          attrs: {
            '.port-body': {
              fill: '#BDBDBD',
              stroke: 'none',
              r: 5,
              magnet: true,
            },
          },
          label: {
            position: {
              name: 'absolute',
              args: { y: -50 },
            },
          },
        },
        bottom: {
          position: {
            name: 'absolute',
            args: { y: 60 },
          },
          attrs: {
            '.port-body': {
              fill: '#BDBDBD',
              stroke: 'none',
              r: 5,
              magnet: true,
            },
            text: {
              x: 10,
              text: '✗',
              fill: 'black',
            },
          },
        },
        right: {
          position: {
            name: 'absolute',
            args: { x: '48%', y: '48%' },
          },
          attrs: {
            '.port-body': {
              fill: '#BDBDBD',
              stroke: 'none',
              r: 5,
              magnet: true,
            },
            text: {
              x: 10,
              text: '✓',
              fill: 'black',
            },
          },
        },
      },
    });

    return newNode;
  }

  ifSegmentsNode(
    x: number,
    y: number,
    data: any = {
      original: ulid(),
      name: 'customerCaseSegments',
      percentage: 0,
      cases: {},
    },
  ) {
    const newNode = new ConditionalSegmentNode();
    newNode.resize(120, 60);
    newNode.position(x, y);
    newNode.attr({
      path: {
        d: 'M 60 0 L 120 30 60 60 0 30 z',
        fill: '#A67B5B',
        stroke: 'none',
        filter: { name: 'dropShadow', args: { dx: 2, dy: 2, blur: 3, color: 'rgba(0,0,0,0.2)' } },
      },
      text: {
        text: this.translate.instant(
          `DATA.MODULES.CAMPAINGS.ELEMENTS.NODES.NAMING.customerCaseSegments`,
        ),
        fill: '#A67B5B',
        fontSize: 9,
        fontFamily: 'Helvetica',
        fontWeight: 'bold',
      },
    });
    newNode.set('nodeType', 'customerCaseSegments');
    newNode.set('data', data);

    newNode.addPort({ group: 'top' });
    newNode.addPort({ group: 'bottom' });
    newNode.addPort({ group: 'right' });

    newNode.prop('ports', {
      groups: {
        top: {
          position: {
            name: 'absolute',
            args: { y: 0 },
          },
          attrs: {
            '.port-body': {
              fill: '#BDBDBD',
              stroke: 'none',
              r: 5,
              magnet: true,
            },
          },
          label: {
            position: {
              name: 'absolute',
              args: { y: -50 },
            },
          },
        },
        bottom: {
          position: {
            name: 'absolute',
            args: { y: 60 },
          },
          attrs: {
            '.port-body': {
              fill: '#BDBDBD',
              stroke: 'none',
              r: 5,
              magnet: true,
            },
            text: {
              x: 10,
              text: '✗',
              fill: 'black',
            },
          },
        },
        right: {
          position: {
            name: 'absolute',
            args: { x: '48%', y: '48%' },
          },
          attrs: {
            '.port-body': {
              fill: '#BDBDBD',
              stroke: 'none',
              r: 5,
              magnet: true,
            },
            text: {
              x: 10,
              text: '✓',
              fill: 'black',
            },
          },
        },
      },
    });

    return newNode;
  }

  experimentNode(
    x: number,
    y: number,
    data: any = {
      original: ulid(),
      name: 'experiment',
      segmentIds: [],
      cases: {},
    },
  ) {
    const newNode = new ExperimentNode();
    newNode.resize(120, 60);
    newNode.position(x, y);
    newNode.attr({
      path: {
        d: 'M 60 0 L 120 30 60 60 0 30 z',
        fill: '#891e35',
        stroke: 'none',
        filter: { name: 'dropShadow', args: { dx: 2, dy: 2, blur: 3, color: 'rgba(0,0,0,0.2)' } },
      },
      text: {
        text: 'Experimento',
        fill: '#891e35',
        fontSize: 9,
        fontFamily: 'Helvetica',
        fontWeight: 'bold',
      },
    });
    newNode.set('nodeType', 'customerCaseSegments');
    newNode.set('data', data);

    newNode.addPort({ group: 'top' });
    newNode.addPort({ group: 'bottom' });
    newNode.addPort({ group: 'right' });

    newNode.prop('ports', {
      groups: {
        top: {
          position: {
            name: 'absolute',
            args: { y: 0 },
          },
          attrs: {
            '.port-body': {
              fill: '#BDBDBD',
              stroke: 'none',
              r: 5,
              magnet: true,
            },
          },
          label: {
            position: {
              name: 'absolute',
              args: { y: -50 },
            },
          },
        },
        bottom: {
          position: {
            name: 'absolute',
            args: { y: 60 },
          },
          attrs: {
            '.port-body': {
              fill: '#BDBDBD',
              stroke: 'none',
              r: 5,
              magnet: true,
            },
            text: {
              x: 10,
              text: '✗',
              fill: 'black',
            },
          },
        },
        right: {
          position: {
            name: 'absolute',
            args: { x: '48%', y: '48%' },
          },
          attrs: {
            '.port-body': {
              fill: '#BDBDBD',
              stroke: 'none',
              r: 5,
              magnet: true,
            },
            text: {
              x: 10,
              text: '✓',
              fill: 'black',
            },
          },
        },
      },
    });

    return newNode;
  }

  startNode(
    x: number,
    y: number,
    data: any = {
      original: '1',
      name: 'start',
    },
  ) {
    const newNode = new joint.shapes.standard.Circle();
    newNode.resize(80, 80);
    newNode.position(x, y);
    newNode.attr({
      body: {
        fill: '#F9F9F9',
        stroke: '#81c784',
        strokeWidth: 5,
        filter: { name: 'dropShadow', args: { dx: 2, dy: 2, blur: 3, color: 'rgba(0,0,0,0.2)' } },
      },

      label: {
        text: this.translate.instant(`DATA.MODULES.CAMPAINGS.ELEMENTS.NODES.NAMING.start`),
        fill: '#81c784',
        refX: 0.5,
        refY: 0.5,
        ...baseLabelOpts,
      },
    });
    newNode.set('nodeType', 'event');
    newNode.set('data', data);

    newNode.addPort({ group: 'bottom' });

    newNode.prop('ports', {
      groups: {
        bottom: {
          position: {
            name: 'bottom',
          },
          attrs: {
            circle: { fill: '#BDBDBD', stroke: 'none', r: 5, magnet: true },
          },
        },
      },
    });

    return newNode;
  }

  offerPaymentAgreementNode(
    x: number,
    y: number,
    data: any = {
      original: ulid(),
      name: 'offerPaymentAgreement',
      expirationDays: '',
      numberOfInstalments: '',
    },
  ) {
    const newNode = new joint.shapes.standard.Polygon();
    newNode.resize(80, 80);
    newNode.position(x, y);
    newNode.attr({
      body: {
        refPoints: '75,90 115,50 95,10 55,10 35,50',
        fill: '#F9F9F9',
        stroke: 'indigo',
        strokeWidth: 5,
        filter: { name: 'dropShadow', args: { dx: 2, dy: 2, blur: 3, color: 'rgba(0,0,0,0.2)' } },
      },
      label: {
        textWrap: {
          text: this.translate.instant(
            `DATA.MODULES.CAMPAINGS.ELEMENTS.NODES.NAMING.offerPaymentAgreement`,
          ),
          width: -20,
          breakWords: true,
        },
        fill: 'indigo',
        refX: 0.5,
        refY: 0.5,
        textVerticalAnchor: 'middle',
        textAnchor: 'middle',
        fontSize: 10,
        fontWeight: 'bold',
      },
    });
    newNode.set('nodeType', 'OfferPaymentAgreement');
    newNode.set('data', data);

    newNode.addPort({ group: 'top' });
    newNode.addPort({ group: 'bottom' });

    newNode.prop('ports', {
      groups: {
        top: {
          position: {
            name: 'top',
          },
          attrs: {
            circle: {
              fill: '#BDBDBD',
              stroke: 'none',
              r: 5,
              magnet: 'passive',
            },
          },
        },
        bottom: {
          position: {
            name: 'bottom',
          },
          attrs: {
            circle: { fill: '#BDBDBD', stroke: 'none', r: 5, magnet: true },
          },
        },
      },
    });

    return newNode;
  }

  makeOutboundCallNode(
    x: number,
    y: number,
    data: any = {
      original: ulid(),
      name: 'makeOutboundCall',
      voiceId: '',
      copyId: '',
    },
  ) {
    const newNode = new joint.shapes.standard.Rectangle();
    newNode.resize(100, 60);
    newNode.position(x, y);
    newNode.attr({
      body: {
        fill: '#F9F9F9',
        stroke: '#00c0ac',
        strokeWidth: 5,
        rx: 20,
        ry: 20,
      },

      label: {
        textWrap: {
          text: this.translate.instant(
            `DATA.MODULES.CAMPAINGS.ELEMENTS.NODES.NAMING.makeOutboundCall`,
          ),
          width: -20,
          breakWords: true,
        },
        fill: '#00c0ac',
        refX: 0.5,
        refY: 0.5,
        ...baseLabelOpts,
      },
    });
    newNode.set('nodeType', 'makeOutboundCall');
    newNode.set('data', data);

    newNode.addPort({ group: 'top' });
    newNode.addPort({ group: 'bottom' });

    newNode.prop('ports', {
      groups: {
        top: {
          position: {
            name: 'top',
          },
          attrs: {
            circle: {
              fill: '#BDBDBD',
              stroke: 'none',
              r: 5,
              magnet: 'passive',
            },
          },
        },
        bottom: {
          position: {
            name: 'bottom',
          },
          attrs: {
            circle: { fill: '#BDBDBD', stroke: 'none', r: 5, magnet: true },
          },
        },
      },
    });

    return newNode;
  }

  partialPaymentNode(
    x: number,
    y: number,
    data: any = {
      original: ulid(),
      name: 'offerPartialPayment',
      expirationDays: 0,
      minimumAmount: { amount: '', currency: '' },
      minimumPercentageAmount: '',
    },
  ) {
    const newNode = new joint.shapes.standard.Polygon();
    newNode.resize(80, 80);
    newNode.position(x, y);
    newNode.attr({
      body: {
        refPoints: '50,0 93.3,25 93.3,75 50,100 6.7,75 6.7,25',
        fill: '#F9F9F9',
        stroke: '#9683EC',
        strokeWidth: 5,
        filter: { name: 'dropShadow', args: { dx: 2, dy: 2, blur: 3, color: 'rgba(0,0,0,0.2)' } },
      },
      label: {
        textWrap: {
          text: this.translate.instant(
            `DATA.MODULES.CAMPAINGS.ELEMENTS.NODES.NAMING.offerPartialPayment`,
          ),
          width: -20,
          breakWords: true,
        },
        breakWords: true,
        fill: '#9683EC',
        refX: 0.5,
        refY: 0.5,
        textVerticalAnchor: 'middle',
        textAnchor: 'middle',
        fontSize: 10,
        fontWeight: 'bold',
      },
    });
    newNode.set('nodeType', 'PartialPayment');
    newNode.set('data', data);

    newNode.addPort({ group: 'top' });
    newNode.addPort({ group: 'bottom' });

    newNode.prop('ports', {
      groups: {
        top: {
          position: {
            name: 'top',
          },
          attrs: {
            circle: {
              fill: '#BDBDBD',
              stroke: 'none',
              r: 5,
              magnet: 'passive',
            },
          },
        },
        bottom: {
          position: {
            name: 'bottom',
          },
          attrs: {
            circle: { fill: '#BDBDBD', stroke: 'none', r: 5, magnet: true },
          },
        },
      },
    });

    return newNode;
  }
  endNode(
    x: number,
    y: number,
    data: any = {
      original: ulid(),
      name: 'end',
    },
  ) {
    const newNode = new joint.shapes.standard.Circle();
    newNode.resize(80, 80);
    newNode.position(x, y);
    newNode.attr({
      body: {
        fill: '#F9F9F9',
        stroke: '#e57373',
        strokeWidth: 5,
        filter: { name: 'dropShadow', args: { dx: 2, dy: 2, blur: 3, color: 'rgba(0,0,0,0.2)' } },
      },

      label: {
        text: this.translate.instant(`DATA.MODULES.CAMPAINGS.ELEMENTS.NODES.NAMING.end`),
        fill: '#e57373',
        refX: 0.5,
        refY: 0.5,
        ...baseLabelOpts,
      },
    });
    newNode.set('nodeType', 'event');
    newNode.set('data', data);

    newNode.addPort({ group: 'top' });

    newNode.prop('ports', {
      groups: {
        top: {
          position: {
            name: 'top',
          },
          attrs: {
            circle: {
              fill: '#BDBDBD',
              stroke: 'none',
              r: 5,
              magnet: 'passive',
            },
          },
        },
      },
    });

    return newNode;
  }
  waitNode(
    x: number,
    y: number,
    data: any = {
      original: ulid(),
      name: 'waitDays',
      partDay: '',
    },
  ) {
    const newNode = new joint.shapes.standard.Circle();
    newNode.resize(80, 80);
    newNode.position(x, y);
    newNode.attr({
      body: {
        fill: '#F9F9F9',
        stroke: '#f1c40f',
        strokeWidth: 5,
      },

      label: {
        text: 'Esperar',
        fill: '#f1c40f',
        refX: 0.5,
        refY: 0.5,
        ...baseLabelOpts,
      },
    });

    newNode.set('nodeType', 'event');
    newNode.set('data', data);

    newNode.addPort({ group: 'top' });
    newNode.addPort({ group: 'bottom' });

    newNode.prop('ports', {
      groups: {
        top: {
          position: {
            name: 'top',
          },
          attrs: {
            circle: {
              fill: '#BDBDBD',
              stroke: 'none',
              r: 5,
              magnet: 'passive',
            },
          },
        },
        bottom: {
          position: {
            name: 'bottom',
          },
          attrs: {
            circle: { fill: '#BDBDBD', stroke: 'none', r: 5, magnet: true },
          },
        },
      },
    });

    return newNode;
  }
  waitDueDate(
    x: number,
    y: number,
    data: any = {
      original: ulid(),
      name: 'waitDaysForDueDate',
      partDay: '',
    },
  ) {
    const newNode = new joint.shapes.standard.Circle();
    newNode.resize(80, 80);
    newNode.position(x, y);
    newNode.attr({
      body: {
        fill: '#F9F9F9',
        stroke: '#f1c40f',
        strokeWidth: 5,
        filter: { name: 'dropShadow', args: { dx: 2, dy: 2, blur: 3, color: 'rgba(0,0,0,0.2)' } },
      },

      label: {
        textWrap: {
          text: this.translate.instant(
            `DATA.MODULES.CAMPAINGS.ELEMENTS.NODES.NAMING.waitDaysForDueDate`,
          ),
          width: -20,
          breakWords: true,
        },
        lineHeight: '1.5em',
        alignmentBaseline: 'middle',
        textAnchor: 'middle',
        fill: '#f1c40f',
        refX: 0.5,
        refY: 0.5,
        ...baseLabelOpts,
      },
    });

    newNode.set('nodeType', 'event');
    newNode.set('data', data);

    newNode.addPort({ group: 'top' });
    newNode.addPort({ group: 'bottom' });

    newNode.prop('ports', {
      groups: {
        top: {
          position: {
            name: 'top',
          },
          attrs: {
            circle: {
              fill: '#BDBDBD',
              stroke: 'none',
              r: 5,
              magnet: 'passive',
            },
          },
        },
        bottom: {
          position: {
            name: 'bottom',
          },
          attrs: {
            circle: { fill: '#BDBDBD', stroke: 'none', r: 5, magnet: true },
          },
        },
      },
    });

    return newNode;
  }
  waitDayOfWeek(
    x: number,
    y: number,
    data: any = {
      original: ulid(),
      name: 'waitDayOfWeek',
      partDay: '',
    },
  ) {
    const newNode = new joint.shapes.standard.Circle();
    newNode.resize(80, 80);
    newNode.position(x, y);
    newNode.attr({
      body: {
        fill: '#F9F9F9',
        stroke: '#f1c40f',
        strokeWidth: 5,
        filter: { name: 'dropShadow', args: { dx: 2, dy: 2, blur: 3, color: 'rgba(0,0,0,0.2)' } },
      },

      label: {
        textWrap: {
          text: this.translate.instant(
            `DATA.MODULES.CAMPAINGS.ELEMENTS.NODES.NAMING.waitDayOfWeek`,
          ),
          width: -20,
          breakWords: true,
        },
        lineHeight: '1.5em',
        alignmentBaseline: 'middle',
        textAnchor: 'middle',
        fill: '#f1c40f',
        refX: 0.5,
        refY: 0.5,
        ...baseLabelOpts,
      },
    });

    newNode.set('nodeType', 'event');
    newNode.set('data', data);

    newNode.addPort({ group: 'top' });
    newNode.addPort({ group: 'bottom' });

    newNode.prop('ports', {
      groups: {
        top: {
          position: {
            name: 'top',
          },
          attrs: {
            circle: {
              fill: '#BDBDBD',
              stroke: 'none',
              r: 5,
              magnet: 'passive',
            },
          },
        },
        bottom: {
          position: {
            name: 'bottom',
          },
          attrs: {
            circle: { fill: '#BDBDBD', stroke: 'none', r: 5, magnet: true },
          },
        },
      },
    });

    return newNode;
  }
  waitMonth(
    x: number,
    y: number,
    data: any = {
      original: ulid(),
      name: 'waitDayOfMonth',
      partDay: '',
    },
  ) {
    const newNode = new joint.shapes.standard.Circle();
    newNode.resize(80, 80);
    newNode.position(x, y);
    newNode.attr({
      body: {
        fill: '#F9F9F9',
        stroke: '#f1c40f',
        strokeWidth: 5,
        filter: { name: 'dropShadow', args: { dx: 2, dy: 2, blur: 3, color: 'rgba(0,0,0,0.2)' } },
      },

      label: {
        textWrap: {
          text: this.translate.instant(
            `DATA.MODULES.CAMPAINGS.ELEMENTS.NODES.NAMING.waitDayOfMonth`,
          ),
          width: -20,
          breakWords: true,
        },
        lineHeight: '1.5em',
        alignmentBaseline: 'middle',
        textAnchor: 'middle',
        fill: '#f1c40f',
        refX: 0.5,
        refY: 0.5,
        ...baseLabelOpts,
      },
    });

    newNode.set('nodeType', 'event');
    newNode.set('data', data);

    newNode.addPort({ group: 'top' });
    newNode.addPort({ group: 'bottom' });

    newNode.prop('ports', {
      groups: {
        top: {
          position: {
            name: 'top',
          },
          attrs: {
            circle: {
              fill: '#BDBDBD',
              stroke: 'none',
              r: 5,
              magnet: 'passive',
            },
          },
        },
        bottom: {
          position: {
            name: 'bottom',
          },
          attrs: {
            circle: { fill: '#BDBDBD', stroke: 'none', r: 5, magnet: true },
          },
        },
      },
    });

    return newNode;
  }
  waitDays(
    x: number,
    y: number,
    data: any = {
      original: ulid(),
      name: 'waitDays',
      partDay: '',
    },
  ) {
    const newNode = new joint.shapes.standard.Circle();
    newNode.resize(80, 80);
    newNode.position(x, y);
    newNode.attr({
      body: {
        fill: '#F9F9F9',
        stroke: '#f1c40f',
        strokeWidth: 5,
        filter: { name: 'dropShadow', args: { dx: 2, dy: 2, blur: 3, color: 'rgba(0,0,0,0.2)' } },
      },

      label: {
        textWrap: {
          text: this.translate.instant(`DATA.MODULES.CAMPAINGS.ELEMENTS.NODES.NAMING.waitDays`),
          width: -20,
          breakWords: true,
        },
        lineHeight: '1.5em',
        alignmentBaseline: 'middle',
        textAnchor: 'middle',
        fill: '#f1c40f',
        refX: 0.5,
        refY: 0.5,
        ...baseLabelOpts,
      },
    });

    newNode.set('nodeType', 'event');
    newNode.set('data', data);

    newNode.addPort({ group: 'top' });
    newNode.addPort({ group: 'bottom' });

    newNode.prop('ports', {
      groups: {
        top: {
          position: {
            name: 'top',
          },
          attrs: {
            circle: {
              fill: '#BDBDBD',
              stroke: 'none',
              r: 5,
              magnet: 'passive',
            },
          },
        },
        bottom: {
          position: {
            name: 'bottom',
          },
          attrs: {
            circle: { fill: '#BDBDBD', stroke: 'none', r: 5, magnet: true },
          },
        },
      },
    });

    return newNode;
  }
  sendNode(
    x: number,
    y: number,
    data: any = {
      original: ulid(),
      name: 'sendSMS',
      certified: false,
      copyId: '',
      copyIds: [],
    },
  ) {
    const newNode = new joint.shapes.standard.Rectangle();
    newNode.resize(100, 60);
    newNode.position(x, y);
    newNode.attr({
      body: {
        fill: '#F9F9F9',
        stroke: '#0041A8',
        strokeWidth: 5,
        rx: 20,
        ry: 20,
        filter: { name: 'dropShadow', args: { dx: 2, dy: 2, blur: 3, color: 'rgba(0,0,0,0.2)' } },
      },

      label: {
        text: 'Enviar',
        fill: '#0041A8',
        refX: 0.5,
        refY: 0.5,
        ...baseLabelOpts,
      },
    });

    newNode.set('nodeType', 'activity');
    newNode.set('data', data);

    newNode.addPort({ group: 'top' });
    newNode.addPort({ group: 'bottom' });

    newNode.prop('ports', {
      groups: {
        top: {
          position: {
            name: 'top',
          },
          attrs: {
            circle: {
              fill: '#BDBDBD',
              stroke: 'none',
              r: 5,
              magnet: 'passive',
            },
          },
        },
        bottom: {
          position: {
            name: 'bottom',
          },
          attrs: {
            circle: { fill: '#BDBDBD', stroke: 'none', r: 5, magnet: true },
          },
        },
      },
    });

    return newNode;
  }
  sendSMS(
    x: number,
    y: number,
    data: any = {
      original: ulid(),
      name: 'sendSMS',
      certified: false,
      copyId: '',
      copyIds: [],
    },
  ) {
    const newNode = new joint.shapes.standard.Rectangle();
    newNode.resize(100, 60);
    newNode.position(x, y);
    newNode.attr({
      body: {
        fill: '#F9F9F9',
        stroke: '#0041A8',
        strokeWidth: 5,
        rx: 20,
        ry: 20,
        filter: { name: 'dropShadow', args: { dx: 2, dy: 2, blur: 3, color: 'rgba(0,0,0,0.2)' } },
      },

      label: {
        textWrap: {
          text: this.translate.instant(`DATA.MODULES.CAMPAINGS.ELEMENTS.NODES.NAMING.sendSMS`),
          width: -20,
          breakWords: true,
        },
        fill: '#0041A8',
        refX: 0.5,
        refY: 0.5,
        ...baseLabelOpts,
      },

      root: {},
    });

    newNode.set('nodeType', 'activity');
    newNode.set('data', data);

    newNode.addPort({ group: 'top' });
    newNode.addPort({ group: 'bottom' });

    newNode.prop('ports', {
      groups: {
        top: {
          position: {
            name: 'top',
          },
          attrs: {
            circle: {
              fill: '#BDBDBD',
              stroke: 'none',
              r: 5,
              magnet: 'passive',
            },
          },
        },
        bottom: {
          position: {
            name: 'bottom',
          },
          attrs: {
            circle: { fill: '#BDBDBD', stroke: 'none', r: 5, magnet: true },
          },
        },
      },
    });

    return newNode;
  }
  sendCertifiedSMS(
    x: number,
    y: number,
    data: any = {
      original: ulid(),
      name: 'sendCertifiedSMS',
      certified: true,
      copyId: '',
      copyIds: [],
    },
  ) {
    const newNode = new joint.shapes.standard.Rectangle();
    newNode.resize(100, 60);
    newNode.position(x, y);
    newNode.attr({
      body: {
        fill: '#F9F9F9',
        stroke: '#0041A8',
        strokeWidth: 5,
        rx: 20,
        ry: 20,
        filter: { name: 'dropShadow', args: { dx: 2, dy: 2, blur: 3, color: 'rgba(0,0,0,0.2)' } },
      },

      label: {
        textWrap: {
          text: this.translate.instant(
            `DATA.MODULES.CAMPAINGS.ELEMENTS.NODES.NAMING.sendCertifiedSMS`,
          ),
          width: -20,
          breakWords: true,
        },
        fill: '#0041A8',
        refX: 0.5,
        refY: 0.5,
        ...baseLabelOpts,
      },
    });

    newNode.set('nodeType', 'activity');
    newNode.set('data', data);

    newNode.addPort({ group: 'top' });
    newNode.addPort({ group: 'bottom' });

    newNode.prop('ports', {
      groups: {
        top: {
          position: {
            name: 'top',
          },
          attrs: {
            circle: {
              fill: '#BDBDBD',
              stroke: 'none',
              r: 5,
              magnet: 'passive',
            },
          },
        },
        bottom: {
          position: {
            name: 'bottom',
          },
          attrs: {
            circle: { fill: '#BDBDBD', stroke: 'none', r: 5, magnet: true },
          },
        },
      },
    });

    return newNode;
  }
  sendCertifiedEmail(
    x: number,
    y: number,
    data: any = {
      original: ulid(),
      name: 'sendCertifiedEmail',
      certified: true,
      copyId: '',
      copyIds: [],
    },
  ) {
    const newNode = new joint.shapes.standard.Rectangle();
    newNode.resize(100, 60);
    newNode.position(x, y);
    newNode.attr({
      body: {
        fill: '#F9F9F9',
        stroke: '#0095FF',
        strokeWidth: 5,
        rx: 20,
        ry: 20,
        filter: { name: 'dropShadow', args: { dx: 2, dy: 2, blur: 3, color: 'rgba(0,0,0,0.2)' } },
      },

      label: {
        textWrap: {
          text: this.translate.instant(
            `DATA.MODULES.CAMPAINGS.ELEMENTS.NODES.NAMING.sendCertifiedEmail`,
          ),
          width: -20,
          breakWords: true,
        },
        fill: '#0095FF',
        refX: 0.5,
        refY: 0.5,
        ...baseLabelOpts,
      },
    });

    newNode.set('nodeType', 'activity');
    newNode.set('data', data);

    newNode.addPort({ group: 'top' });
    newNode.addPort({ group: 'bottom' });

    newNode.prop('ports', {
      groups: {
        top: {
          position: {
            name: 'top',
          },
          attrs: {
            circle: {
              fill: '#BDBDBD',
              stroke: 'none',
              r: 5,
              magnet: 'passive',
            },
          },
        },
        bottom: {
          position: {
            name: 'bottom',
          },
          attrs: {
            circle: { fill: '#BDBDBD', stroke: 'none', r: 5, magnet: true },
          },
        },
      },
    });

    return newNode;
  }
  sendEmail(
    x: number,
    y: number,
    data: any = {
      original: ulid(),
      name: 'sendEmail',
      certified: false,
      copyId: '',
      copyIds: [],
    },
  ) {
    const newNode = new joint.shapes.standard.Rectangle();
    newNode.resize(100, 60);
    newNode.position(x, y);
    newNode.attr({
      body: {
        fill: '#F9F9F9',
        stroke: '#0095FF',
        strokeWidth: 5,
        rx: 20,
        ry: 20,
        filter: { name: 'dropShadow', args: { dx: 2, dy: 2, blur: 3, color: 'rgba(0,0,0,0.2)' } },
      },

      label: {
        textWrap: {
          text: this.translate.instant(`DATA.MODULES.CAMPAINGS.ELEMENTS.NODES.NAMING.sendEmail`),
          width: -20,
          breakWords: true,
        },
        fill: '#0095FF',
        refX: 0.5,
        refY: 0.5,
        ...baseLabelOpts,
      },
    });

    newNode.set('nodeType', 'activity');
    newNode.set('data', data);

    newNode.addPort({ group: 'top' });
    newNode.addPort({ group: 'bottom' });

    newNode.prop('ports', {
      groups: {
        top: {
          position: {
            name: 'top',
          },
          attrs: {
            circle: {
              fill: '#BDBDBD',
              stroke: 'none',
              r: 5,
              magnet: 'passive',
            },
          },
        },
        bottom: {
          position: {
            name: 'bottom',
          },
          attrs: {
            circle: { fill: '#BDBDBD', stroke: 'none', r: 5, magnet: true },
          },
        },
      },
    });

    return newNode;
  }

  superSendSMS(
    x: number,
    y: number,
    data: any = {
      original: ulid(),
      name: 'superSendSMS',
      certified: false,
      copyId: '',
      copyIds: [],
    },
  ) {
    const newNode = new joint.shapes.standard.Rectangle();
    newNode.resize(100, 60);
    newNode.position(x, y);
    newNode.attr({
      body: {
        fill: '#F9F9F9',
        stroke: '#0041A8',
        strokeWidth: 5,
        filter: { name: 'dropShadow', args: { dx: 2, dy: 2, blur: 3, color: 'rgba(0,0,0,0.2)' } },
      },
      label: {
        textWrap: {
          text: this.translate.instant(`DATA.MODULES.CAMPAINGS.ELEMENTS.NODES.NAMING.sms_plus`),
          width: -20,
          breakWords: true,
        },
        fill: '#0041A8',
        refX: 0.5,
        refY: 0.5,
        ...baseLabelOpts,
      },
    });

    newNode.set('nodeType', 'activity');
    newNode.set('data', data);

    newNode.addPort({ group: 'top' });
    newNode.addPort({ group: 'bottom' });

    newNode.prop('ports', {
      groups: {
        top: {
          position: {
            name: 'top',
          },
          attrs: {
            circle: {
              fill: '#BDBDBD',
              stroke: 'none',
              r: 5,
              magnet: 'passive',
            },
          },
        },
        bottom: {
          position: {
            name: 'bottom',
          },
          attrs: {
            circle: { fill: '#BDBDBD', stroke: 'none', r: 5, magnet: true },
          },
        },
      },
    });

    return newNode;
  }

  superSendCertifiedSMS(
    x: number,
    y: number,
    data: any = {
      original: ulid(),
      name: 'superSendCertifiedSMS',
      certified: true,
      copyId: '',
      copyIds: [],
    },
  ) {
    const newNode = new joint.shapes.standard.Rectangle();
    newNode.resize(100, 60);
    newNode.position(x, y);
    newNode.attr({
      body: {
        fill: '#F9F9F9',
        stroke: '#0041A8',
        strokeWidth: 5,
        filter: { name: 'dropShadow', args: { dx: 2, dy: 2, blur: 3, color: 'rgba(0,0,0,0.2)' } },
      },

      label: {
        textWrap: {
          text: this.translate.instant(
            `DATA.MODULES.CAMPAINGS.ELEMENTS.NODES.NAMING.sms_plus_certified`,
          ),
          width: -40,
          breakWords: true,
        },
        fill: '#0041A8',
        refX: 0.5,
        refY: 0.5,
        ...baseLabelOpts,
      },
    });

    newNode.set('nodeType', 'activity');
    newNode.set('data', data);

    newNode.addPort({ group: 'top' });
    newNode.addPort({ group: 'bottom' });

    newNode.prop('ports', {
      groups: {
        top: {
          position: {
            name: 'top',
          },
          attrs: {
            circle: {
              fill: '#BDBDBD',
              stroke: 'none',
              r: 5,
              magnet: 'passive',
            },
          },
        },
        bottom: {
          position: {
            name: 'bottom',
          },
          attrs: {
            circle: { fill: '#BDBDBD', stroke: 'none', r: 5, magnet: true },
          },
        },
      },
    });

    return newNode;
  }
  superSendCertifiedEmail(
    x: number,
    y: number,
    data: any = {
      original: ulid(),
      name: 'superSendCertifiedEmail',
      certified: true,
      copyId: '',
      copyIds: [],
    },
  ) {
    const newNode = new joint.shapes.standard.Rectangle();
    newNode.resize(100, 60);
    newNode.position(x, y);
    newNode.attr({
      body: {
        fill: '#F9F9F9',
        stroke: '#0095FF',
        strokeWidth: 5,
        filter: { name: 'dropShadow', args: { dx: 2, dy: 2, blur: 3, color: 'rgba(0,0,0,0.2)' } },
      },

      label: {
        textWrap: {
          text: this.translate.instant(
            `DATA.MODULES.CAMPAINGS.ELEMENTS.NODES.NAMING.email_plus_certified`,
          ),
          width: -40,
          breakWords: true,
        },
        fill: '#0095FF',
        refX: 0.5,
        refY: 0.5,
        ...baseLabelOpts,
      },
    });

    newNode.set('nodeType', 'activity');
    newNode.set('data', data);

    newNode.addPort({ group: 'top' });
    newNode.addPort({ group: 'bottom' });

    newNode.prop('ports', {
      groups: {
        top: {
          position: {
            name: 'top',
          },
          attrs: {
            circle: {
              fill: '#BDBDBD',
              stroke: 'none',
              r: 5,
              magnet: 'passive',
            },
          },
        },
        bottom: {
          position: {
            name: 'bottom',
          },
          attrs: {
            circle: { fill: '#BDBDBD', stroke: 'none', r: 5, magnet: true },
          },
        },
      },
    });

    return newNode;
  }
  superSendEmail(
    x: number,
    y: number,
    data: any = {
      original: ulid(),
      name: 'superSendEmail',
      certified: false,
      copyId: '',
      copyIds: [],
    },
  ) {
    const newNode = new joint.shapes.standard.Rectangle();
    newNode.resize(100, 60);
    newNode.position(x, y);
    newNode.attr({
      body: {
        fill: '#F9F9F9',
        stroke: '#0095FF',
        strokeWidth: 5,
        filter: { name: 'dropShadow', args: { dx: 2, dy: 2, blur: 3, color: 'rgba(0,0,0,0.2)' } },
      },

      label: {
        textWrap: {
          text: this.translate.instant(`DATA.MODULES.CAMPAINGS.ELEMENTS.NODES.NAMING.email_plus`),
          width: -20,
          breakWords: true,
        },
        fill: '#0095FF',
        refX: 0.5,
        refY: 0.5,
        ...baseLabelOpts,
      },
    });

    newNode.set('nodeType', 'activity');
    newNode.set('data', data);

    newNode.addPort({ group: 'top' });
    newNode.addPort({ group: 'bottom' });

    newNode.prop('ports', {
      groups: {
        top: {
          position: {
            name: 'top',
          },
          attrs: {
            circle: {
              fill: '#BDBDBD',
              stroke: 'none',
              r: 5,
              magnet: 'passive',
            },
          },
        },
        bottom: {
          position: {
            name: 'bottom',
          },
          attrs: {
            circle: { fill: '#BDBDBD', stroke: 'none', r: 5, magnet: true },
          },
        },
      },
    });

    return newNode;
  }

  assignNode(
    x: number,
    y: number,
    data: any = {
      original: ulid(),
      name: 'assignCampaign',
      targetCampaignId: '',
    },
  ) {
    const newNode = new joint.shapes.standard.Rectangle();
    newNode.resize(100, 60);
    newNode.position(x, y);
    newNode.attr({
      body: {
        fill: '#F9F9F9',
        stroke: '#6449b4',
        strokeWidth: 5,
        rx: 20,
        ry: 20,
        filter: { name: 'dropShadow', args: { dx: 2, dy: 2, blur: 3, color: 'rgba(0,0,0,0.2)' } },
      },

      label: {
        fill: '#6449b4',
        refX: 0.5,
        refY: 0.5,
        ...baseLabelOpts,
        textWrap: {
          text: this.translate.instant(
            `DATA.MODULES.CAMPAINGS.ELEMENTS.NODES.NAMING.assignCampaign`,
          ),
          width: -20,
          breakWords: true,
        },
      },
    });

    newNode.set('nodeType', 'activity');
    newNode.set('data', data);

    newNode.addPort({ group: 'top' });

    newNode.prop('ports', {
      groups: {
        top: {
          position: {
            name: 'top',
          },
          attrs: {
            circle: {
              fill: '#BDBDBD',
              stroke: 'none',
              r: 5,
              magnet: 'passive',
            },
          },
        },
      },
    });

    return newNode;
  }

  multiConditionalNode(
    x: number,
    y: number,
    data: any = {
      original: ulid(),
      name: 'multiconditionalNode',
    },
  ) {
    const newNode = new MultiportsNode();
    newNode.resize(120, 60);
    newNode.position(x, y);
    newNode.attr({
      path: {
        d: 'M 60 0 L 120 30 60 60 0 30 z',
        fill: '#48ff00',
        stroke: 'none',
      },
      text: {
        text: 'Multiconditional',
        fill: '#21ff00',
        fontSize: 10,
        fontFamily: 'Helvetica',
        fontWeight: 'bold',
      },
    });
    newNode.set('nodeType', 'multicondtional');
    newNode.set('data', data);

    newNode.addPort({ group: 'top' });
    newNode.addPort({ group: 'bottom' });
    newNode.addPort({ group: 'right' });
    newNode.addPort({ group: 'left' });
    newNode.addPort({
      group: 'outPorts',
      attrs: { '.port-body': { fill: '#E6A502' } },
      id: 'customOut1',
    });
    newNode.addPort({
      group: 'outPorts',
      attrs: { '.port-body': { fill: '#E6A502' } },
      id: 'customOut2',
    });

    newNode.prop('ports', {
      groups: {
        top: {
          position: {
            name: 'absolute',
            args: { y: 0 },
          },
          attrs: {
            '.port-body': {
              fill: '#BDBDBD',
              stroke: 'none',
              r: 5,
              magnet: true,
            },
          },
          label: {
            position: {
              args: { y: -50 },
            },
          },
        },
        outPorts: {
          position: {
            name: 'bottom',
          },
          attrs: {
            '.port-body': {
              fill: '#BDBDBD',
              stroke: 'none',
              r: 5,
              magnet: true,
            },
          },
        },
        bottom: {
          position: {
            name: 'absolute',
            args: { y: 60 },
          },
          attrs: {
            '.port-body': {
              fill: '#BDBDBD',
              stroke: 'none',
              r: 5,
              magnet: true,
            },
            text: {
              x: 10,
              fill: 'black',
            },
          },
        },
        left: {
          position: {
            position: {
              name: 'absolute',
              args: { x: '-48%', y: '-48%' },
            },
          },
          attrs: {
            '.port-body': {
              fill: '#BDBDBD',
              stroke: 'none',
              r: 5,
              magnet: true,
            },
          },
          text: {
            x: 10,
            text: '',
            fill: 'black',
          },
        },
      },
      right: {
        position: {
          name: 'absolute',
          args: { x: '48%', y: '48%' },
        },
        attrs: {
          '.port-body': {
            fill: '#BDBDBD',
            stroke: 'none',
            r: 5,
            magnet: true,
          },
          text: {
            x: 10,
            text: '✓',
            fill: 'black',
          },
        },
      },
    });

    return newNode;
  }

  errorNode(
    x: number,
    y: number,
    data: any = {
      original: ulid(),
      name: 'error',
    },
  ) {
    const newNode = new joint.shapes.standard.Polygon();
    newNode.resize(100, 60);
    newNode.position(x, y);
    newNode.attr({
      body: {
        fill: '#F9F9F9',
        stroke: 'red',
        strokeWidth: 5,
      },

      label: {
        text: 'Error',
        fill: 'red',
        refX: 0.5,
        refY: 0.6,
        ...baseLabelOpts,
      },
    });

    newNode.attr('body/refPoints', '0,100 50,0 100,100');

    newNode.set('nodeType', 'error');
    newNode.set('data', data);

    newNode.addPort({ group: 'top' });
    newNode.addPort({ group: 'bottom' });

    newNode.prop('ports', {
      groups: {
        top: {
          position: {
            name: 'top',
          },
          attrs: {
            circle: {
              fill: '#BDBDBD',
              stroke: 'none',
              r: 5,
              magnet: 'passive',
            },
          },
        },
        bottom: {
          position: {
            name: 'bottom',
          },
          attrs: {
            circle: { fill: '#BDBDBD', stroke: 'none', r: 5, magnet: true },
          },
        },
      },
    });

    return newNode;
  }

  translateDraggables(draggables: draggableMenu): draggableMenu {
    const translatedDraggables: draggableMenu = { ...draggables };

    Object.keys(draggables).forEach((key) => {
      const items = draggables[key as keyof draggableMenu];
      translatedDraggables[key as keyof draggableMenu] = items.map((item) => ({
        ...item,
        name: this.translate.instant(`DATA.MODULES.CAMPAINGS.ELEMENTS.NODES.NAMING.${item.id}`),
      }));
    });

    return translatedDraggables;
  }
}
