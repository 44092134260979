import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { CollectorModel } from '@admin/modules/collectors/model/collectors.model';
import { CurrentUserService } from '@core/services/currentUser.service';
import { DynamicFormComponent } from '@shared/components/dynamic-form/dynamic-form.component';
import { FormInputBase } from '@shared/components/dynamic-form/models/form-input-base';
import { UserAllowsCollectorsID } from '@shared/helpers/collectors.helper';
import { FormCascade } from '@shared/components/dynamic-form/models';
import { SetCampaignService } from '@shared/components/dashboard/services/setCampaign.service';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-filters-dropdown',
  templateUrl: './filters.component.html',
})
export class FiltersComponent implements OnInit {
  @ViewChild('form') form!: DynamicFormComponent;
  @Input() filterInputs?: (FormInputBase<string | boolean> | FormCascade)[] = [];

  @Input() filterValues?: {
    reportType: string;
    collectors: CollectorModel[];
    campaigns: any;
    portfolios: any;
    operationStatus: any;
    isMultiCurrency: boolean;
  };
  @Output() values = new EventEmitter<{ data: any; isValid: boolean }>();

  constructor(
    private currentUser: CurrentUserService,
    private setCampaignsIdService: SetCampaignService,
    private translate: TranslateService,
  ) {}

  ngOnInit(): void {
    this.transformFilters();
    this.setCollectorsID();
    this.setCampaignsIdService.setCampaignsID(this.filterInputs, this.filterValues);
    this.setPorfoliosID();
    this.setOperationStatus();
  }

  transformFilters() {
    type ReportType =
      | 'PANEL'
      | 'CLIENTS'
      | 'CAMPAIGNS'
      | 'COLLECTION'
      | 'COLLECTORS'
      | 'COLLECTION_BY_PERIOD'
      | 'CAMPAIGNS_BY_MONTH'
      | 'PORTFOLIOS';

    if (this.filterValues) {
      const reportType: ReportType = this.filterValues.reportType as ReportType;

      const map: { [key in ReportType]: string[] } = {
        PANEL: ['collectorId'],
        CLIENTS: ['collectorId'],
        COLLECTORS: ['collectorId', 'date'],
        CAMPAIGNS: ['collectorId', 'campaignId', 'dateMonthNotification'],
        CAMPAIGNS_BY_MONTH: ['collectorId', 'campaignId', 'dateMonthNotification'],
        COLLECTION: ['collectorId', 'date', 'campaignIdNoRequired'],
        COLLECTION_BY_PERIOD: [
          'dateMonth',
          'months',
          'campaignIdNoRequired',
          'collectorId',
          'portfolioId',
          'ignoreOperationStatus',
        ],
        PORTFOLIOS: ['collectorId', 'date'],
      };

      this.filterInputs = this.filterInputs!.map((input) => {
        if (reportType === 'COLLECTION_BY_PERIOD' && input.key === 'campaignIdNoRequired') {
          input.label = 'DATA.MODULES.REPORTS.FILTERS.CAMPAIGN_PAYMENTS';
          if (Array.isArray(input.options)) {
            input.options = input.options.filter((option) => option.value !== 'no-workflow');
          }
        }

        if (reportType === 'CAMPAIGNS' && input.key === 'campaignId') {
          input.label = 'DATA.MODULES.REPORTS.FILTERS.CAMPAIGNS';
        }

        return input;
      });

      this.filterInputs = this.filterInputs!.filter((input) => map[reportType].includes(input.key));
    }
  }

  setCollectorsID() {
    if (this.filterValues!.collectors) {
      UserAllowsCollectorsID(
        this.currentUser.currentUser.permissions,
        this.currentUser.currentUser.collectorIds,
        this.filterValues!.collectors,
        this.filterInputs!,
        this.filterInputs!.findIndex((input) => input.key === 'collectorId'),
      );

      const collectorInputIndex = this.filterInputs!.findIndex(
        (input) => input.key === 'collectorId',
      );
      const collectorInput = this.filterInputs![collectorInputIndex];

      if (collectorInput && Array.isArray(collectorInput.options)) {
        if (collectorInput.options.length === 1) {
          this.filterInputs!.splice(collectorInputIndex, 1);
        } else if (
          this.filterValues?.reportType === 'PANEL' &&
          this.filterValues?.isMultiCurrency
        ) {
          collectorInput.value = collectorInput.options[0].value;
          collectorInput.showClear = false;
        }
      }
    }
  }

  setPorfoliosID() {
    if (this.filterValues!.portfolios) {
      this.filterInputs!.find((input) => input.key === 'portfolioId')?.setOptions(
        this.filterValues!.portfolios.map((portfolio: any) => {
          return {
            label: this.translate.instant(`DATA.COMMON.STATUS.${portfolio.id}`),
            value: portfolio.id,
          };
        }),
      );
    }
    return;
  }

  setOperationStatus() {
    if (this.filterValues!.operationStatus) {
      this.filterInputs!.find((input) => input.key === 'ignoreOperationStatus')?.setOptions(
        this.filterValues!.operationStatus.data.operationStatus.map((opStatus: any) => {
          return { label: opStatus.name, value: opStatus.id };
        }),
      );
    }
    return;
  }

  isValid(): boolean {
    return this.form.form.status === 'VALID';
  }

  sendValues(data: any): void {
    if (data.campaignId && typeof data.campaignId === 'object') {
      data.campaignId = data.campaignId.value; // Extract only the value from the campaignId object, because cascade gives all the object at once
    }
    this.values.emit({
      data,
      isValid: this.isValid(),
    });
  }
}
