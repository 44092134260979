import { Injectable, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Apollo } from 'apollo-angular';
import { BehaviorSubject, Observable, catchError, map, throwError } from 'rxjs';
import { ulid } from 'ulid';
import {
  CREATE_SEGMENT,
  DELETE_SEGMENT,
  FIND_SEGMENT,
  GET_SEGMENTS_LIST,
  UPDATE_SEGMENT,
} from '../graphql/segments.graphql';
import { transformfiltersToBackend } from '../helpers/handleData.helper';
import { CurrentUserService } from '@core/services/currentUser.service';
import { PermissionType } from '@admin/modules/users/models/user.model';
import {
  CurrencyInput,
  FormDropdown,
  FormNumber,
  FormTextbox,
} from '@shared/components/dynamic-form/models';
import { TranslateService } from '@ngx-translate/core';
import { generateNumberArray } from '@shared/helpers/variables';
import { startsWithAndEndWith } from '@shared/validators/forms/startsWithAndEndWith';

@Injectable({
  providedIn: 'root',
})
export class SegmentationService {
  isLoading$ = new BehaviorSubject<boolean>(false);
  private apollo = inject(Apollo);
  private route = inject(ActivatedRoute);
  private currentUser = inject(CurrentUserService);

  constructor(private translate: TranslateService) {}

  getSegment(id: string): Observable<any> {
    return this.apollo
      .query({
        query: FIND_SEGMENT,
        variables: {
          id,
        },
      })
      .pipe(map((res: any) => res.data.segment));
  }

  getSegments(): Observable<any> {
    return this.apollo
      .query({
        query: GET_SEGMENTS_LIST,
        variables: {
          tenantId: this.route.snapshot.queryParams['tenant'],
        },
      })
      .pipe(
        map((res: any) => {
          return res.data.segments;
        }),
      );
  }

  createSegment(segment: any): Observable<any> {
    const { name, filters } = segment;

    return this.apollo
      .query({
        query: CREATE_SEGMENT,
        variables: {
          input: {
            id: ulid(),
            tenantId: this.route.snapshot.queryParams['tenant'],
            name,
            filters: transformfiltersToBackend(filters),
          },
        },
      })
      .pipe(
        map((res: any) => res),
        catchError((error) => {
          console.error('Error al crear segmento', error);
          return throwError(() => new Error(error));
        }),
      );
  }

  updateSegment(segment: any): Observable<any> {
    const { name, filters, id, portfolioIds } = segment;
    return this.apollo
      .query({
        query: UPDATE_SEGMENT,
        variables: {
          input: {
            portfolioIds: portfolioIds || [],
            id,
            name,
            filters: transformfiltersToBackend(filters),
          },
        },
      })
      .pipe(map((res: any) => res));
  }

  deleteSegment(id: string): Observable<any> {
    this.isLoading$.next(true);
    return this.apollo
      .query({
        query: DELETE_SEGMENT,
        variables: {
          id,
        },
      })
      .pipe(map((res: any) => res));
  }

  canWrite() {
    return this.currentUser.allowAccessPermission([
      PermissionType.FULL_ACCESS,
      PermissionType.SEGMENT_WRITE,
    ]);
  }

  getGenderOptions() {
    return [
      { value: 'male', label: this.translate.instant('DATA.COMMON.GENDER.MALE') },
      { value: 'female', label: this.translate.instant('DATA.COMMON.GENDER.FEMALE') },
      {
        value: 'not_known',
        label: this.translate.instant('DATA.COMMON.GENDER.NOT_KNOWN'),
      },
      {
        value: 'non_binary',
        label: this.translate.instant('DATA.COMMON.GENDER.NON_BINARY'),
      },
    ];
  }

  getOwnership() {
    return [
      { value: false, label: this.translate.instant('DATA.COMMON.OWNER.BUSINESS') },
      { value: true, label: this.translate.instant('DATA.COMMON.OWNER.PRIVATE') },
    ];
  }

  getSegmentsDrop(): FormDropdown[] {
    return [
      new FormDropdown({
        key: 'segment',
        type: 'dropdown',
        required: true,
        label: this.translate.instant('DATA.MODULES.CAMPAINGS.SEGMENTS.MODAL.INCLUDE_SEGMENTS'),
        value: null,
        options: [],
        multi: true,
        filter: true,
        readonly: false,
        disabled: false,
      }),
    ];
  }
  getNameForm(): FormTextbox[] {
    return [
      new FormTextbox({
        key: 'name',
        type: 'textbox',
        required: true,
        disabled: false,
        readonly: false,
        label: this.translate.instant('DATA.MODULES.CAMPAINGS.SEGMENTS.MODAL.NAME_SEGMENT'),
        value: null,
      }),
    ];
  }
  getVariableForm(): FormTextbox[] {
    return [
      new FormTextbox({
        key: 'field',
        type: 'textbox',
        required: false,
        disabled: false,
        readonly: false,
        label: this.translate.instant('DATA.MODULES.CAMPAINGS.SEGMENTS.MODAL.VAR_FIELD'),
        value: '',
        infoVisibility: true,
        tooltipPosition: 'right',
        infotooltip: this.translate.instant(
          'DATA.MODULES.CAMPAINGS.SEGMENTS.MODAL.INFO_TOOLTIP_VAR_FIELD',
        ),
        validators: [startsWithAndEndWith('$')],
      }),
      new FormTextbox({
        key: 'value',
        type: 'textbox',
        required: false,
        disabled: false,
        readonly: false,
        label: this.translate.instant('DATA.MODULES.CAMPAINGS.SEGMENTS.MODAL.VALUE'),
        value: '',
      }),
    ];
  }

  getAdvancedSegmentationList(): (FormDropdown | CurrencyInput | FormNumber)[] {
    return [
      new FormDropdown({
        key: 'portfolio_ids',
        type: 'dropdown',
        required: false,
        label: this.translate.instant('DATA.MODULES.CAMPAINGS.SEGMENTS.MODAL.PORTFOLIO'),
        value: null,
        options: [],
        multi: true,
        filter: true,
        placeholder: '',
        readonly: false,
      }),

      new FormDropdown({
        key: 'customer_is_company',
        type: 'dropdown',
        required: false,
        label: this.translate.instant('DATA.MODULES.CAMPAINGS.SEGMENTS.MODAL.OWNERSHIP'),
        value: null,
        options: this.getOwnership(),
        readonly: false,
      }),

      new CurrencyInput({
        key: 'min_customer_case_pending_amount',
        type: 'currency',
        required: false,
        label: this.translate.instant('DATA.MODULES.CAMPAINGS.SEGMENTS.MODAL.MIN_PENDING_AMOUNT'),
        value: null,
        placeholder: '0',
        readonly: false,
        inputGroup: true,
        currency: 'EUR',
      }),
      new CurrencyInput({
        key: 'max_customer_case_pending_amount',
        type: 'currency',
        required: false,
        label: this.translate.instant('DATA.MODULES.CAMPAINGS.SEGMENTS.MODAL.MAX_PENDING_AMOUNT'),
        value: null,
        placeholder: '0',
        readonly: false,
        disabled: false,
        inputGroup: true,
        currency: 'EUR',
      }),

      new FormNumber({
        key: 'min_customer_case_overdue_days',
        type: 'number',
        required: false,
        label: this.translate.instant('DATA.MODULES.CAMPAINGS.SEGMENTS.MODAL.MIN_OVERDUE_DAYS'),
        value: null,
        placeholder: '0',
        readonly: false,
        inputGroup: true,
        addon: 'Días',
      }),
      new FormNumber({
        key: 'max_customer_case_overdue_days',
        type: 'number',
        required: false,
        label: this.translate.instant('DATA.MODULES.CAMPAINGS.SEGMENTS.MODAL.MAX_OVERDUE_DAYS'),
        value: null,
        placeholder: '0',
        readonly: false,
        disabled: false,
        inputGroup: true,
        addon: 'Días',
      }),

      new FormNumber({
        key: 'min_customer_case_number_of_payable_operations',
        type: 'number',
        required: false,
        label: this.translate.instant(
          'DATA.MODULES.CAMPAINGS.SEGMENTS.MODAL.MIN_PAYABLE_OPERATIONS',
        ),
        value: null,
        placeholder: '0',
        readonly: false,
      }),

      new FormNumber({
        key: 'max_customer_case_number_of_payable_operations',
        type: 'number',
        required: false,
        label: this.translate.instant(
          'DATA.MODULES.CAMPAINGS.SEGMENTS.MODAL.MAX_PAYABLE_OPERATIONS',
        ),
        value: null,
        placeholder: '0',
        readonly: false,
        disabled: false,
      }),

      new FormDropdown({
        key: 'min_customer_age',
        type: 'dropdown',
        required: false,
        label: this.translate.instant('DATA.MODULES.CAMPAINGS.SEGMENTS.MODAL.MIN_AGE'),
        value: null,
        options: generateNumberArray(),
        readonly: false,
      }),

      new FormDropdown({
        key: 'max_customer_age',
        type: 'dropdown',
        required: false,
        label: this.translate.instant('DATA.MODULES.CAMPAINGS.SEGMENTS.MODAL.MAX_AGE'),
        value: null,
        options: generateNumberArray(),
        readonly: false,
        disabled: false,
      }),

      new FormDropdown({
        key: 'customer_gender',
        type: 'dropdown',
        required: false,
        multi: true,
        label: this.translate.instant('DATA.MODULES.CAMPAINGS.SEGMENTS.MODAL.GENDER'),
        value: null,
        options: this.getGenderOptions(),
        readonly: false,
      }),
    ];
  }
}
