import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Apollo } from 'apollo-angular';
import { GET_CURRENT_USER, UPDATE_CURRENT_USER } from './graphQL/currentUser.GraphQL';
import { AuthService } from '@auth0/auth0-angular';
import { CollectorService } from './collector/collector.service';
import { PermissionType, UserModel } from '@admin/modules/users/models/user.model';
import { CollectorModel } from '@admin/modules/collectors/model/collectors.model';
import { FormDropdown, FormInputBase, FormTextbox } from '@shared/components/dynamic-form/models';
import { Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class CurrentUserService {
  public currentUser: UserModel = {} as UserModel;
  collectorsList: CollectorModel[] = [];
  tenantGlobalService: string = '';

  constructor(
    private apollo: Apollo,
    private authService: AuthService,
    private collectorsService: CollectorService,
    private route: ActivatedRoute,
    private translate: TranslateService,
  ) {
    this.route.queryParams.subscribe(async (params) => {
      const tenantParam = params['tenant'];
      this.tenantGlobalService = params['tenant'];
      if (
        this.currentUser?.permissions?.some((p) => p === PermissionType.FULL_ACCESS) &&
        tenantParam &&
        tenantParam !== this.currentUser.tenantId
      ) {
        await this.setCurrentUser(true, tenantParam);
      }
    });
  }

  async setCurrentUser(fullUser?: boolean, tenantId?: string): Promise<void> {
    const user: any = await new Promise((resolve, reject) => {
      this.authService.user$.subscribe((res) => {
        if (res) {
          resolve(res);
        } else {
          reject();
        }
      });
    });

    const token = user.sub.replace('auth0|', '');

    this.currentUser = await this.getCurrentUser(token);
    this.translate.use(this.currentUser.language);

    if (fullUser) {
      this.currentUser = { ...user, ...this.currentUser };
    }

    if (this.allowAccessPermission([PermissionType.FULL_ACCESS])) {
      this.currentUser.tenantId = tenantId ?? this.tenantGlobalService ?? this.currentUser.tenantId;
      await this.collectorsService
        .getCollectorlistByTenant(tenantId ?? this.currentUser.tenantId)
        .then((res: any) => {
          this.collectorsList = res.searchCollectors;
          this.currentUser.collectorIds = this.collectorsList.map((c: CollectorModel) => c.id);
        });
    }
  }

  getCurrentLanguage(): string {
    return this.currentUser.language || 'es';
  }

  public getCurrentUser(token: string): Promise<any> {
    return new Promise<UserModel>((resolve, reject) => {
      this.apollo
        .query({
          query: GET_CURRENT_USER,
          variables: {
            id: token,
          },
        })
        .subscribe((res: any) => {
          if (res.errors?.length) {
            reject(res);
          } else {
            resolve(res.data.userIdentify);
          }
        });
    });
  }

  public updateCurrentUser(toEditUser: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.apollo
        .query({
          query: UPDATE_CURRENT_USER,
          variables: {
            input: {
              ...toEditUser,
            },
          },
        })
        .subscribe((res: any) => {
          if (res.errors?.length) {
            reject(res);
          } else {
            this.setCurrentUser(res.data.updateUser);
            resolve(res.data.userIdentify);
          }
        });
    });
  }

  public updateLanguageCurrentUser(
    newLanguage: 'en' | 'es',
    extraPermissions: string[],
  ): Promise<any> {
    const user = this.currentUser;
    const input = {
      id: user.id,
      email: user.email,
      name: user.name,
      surname: user.surname,
      collectorIds: user.collectorIds,
      role: user.role,
      extraPermissions: extraPermissions,
      language: newLanguage,
    };

    return new Promise<any>((resolve, reject) => {
      this.apollo
        .query({
          query: UPDATE_CURRENT_USER,
          variables: {
            input: input,
          },
        })
        .subscribe((res: any) => {
          if (res.errors?.length) {
            reject(res);
          } else {
            resolve(res.data.userIdentify);
          }
        });
    });
  }

  public isPermissionAuthorized(permissions: string[]): boolean {
    const user = this.currentUser;
    if (user) {
      return !(
        permissions &&
        !this.isIndexOfArray(user.permissions, permissions) &&
        user.permissions[0] !== PermissionType.FULL_ACCESS
      );
    }
    return false;
  }

  allowAccessPermission = (permissions: PermissionType[]) => {
    const user = this.currentUser;
    return permissions.some((permission) => user.permissions.includes(permission));
  };

  private isIndexOfArray(array: any, searchArray: any): boolean {
    const isIndexed = searchArray.find((searchElement: any) => {
      return array.indexOf(searchElement) !== -1;
    });
    return !!isIndexed;
  }

  getProfileForm(): FormInputBase<string | boolean>[] {
    const languages = [
      { label: 'Español', value: 'es' },
      { label: 'English', value: 'en' },
    ];

    return [
      new FormTextbox({
        key: 'name',
        type: 'text',
        required: false,
        label: this.translate.instant('DATA.MODULES.PROFILE.FORM.NAME'),
        value: '',
        disabled: true,
        editable: true,
      }),

      new FormTextbox({
        key: 'surname',
        type: 'text',
        required: false,
        label: this.translate.instant('DATA.MODULES.PROFILE.FORM.SURNAME'),
        value: '-',
        disabled: true,
        editable: true,
      }),

      new FormTextbox({
        key: 'email',
        type: 'text',
        required: false,
        label: this.translate.instant('DATA.MODULES.PROFILE.FORM.EMAIL'),
        value: '-',
        disabled: true,
        editable: false,
        validators: [Validators.email],
      }),

      new FormDropdown({
        key: 'language',
        type: 'dropdown',
        required: true,
        label: this.translate.instant('DATA.MODULES.PROFILE.FORM.LANGUAGE'),
        value: languages[0].value,
        options: languages,
        multi: false,
        disabled: true,
        editable: true,
      }),

      new FormTextbox({
        key: 'role',
        type: 'text',
        required: false,
        label: this.translate.instant('DATA.MODULES.PROFILE.FORM.ROLE'),
        value: '-',
        disabled: true,
        editable: false,
      }),
    ];
  }
}
