import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastService } from '@core/services/ui';
import { Apollo } from 'apollo-angular';
import { Observable, map } from 'rxjs';
import { ADD_USER, EDIT_USER, GET_USERS, GET_USER, DELETE_USER } from '../graphql/users.graphql';
import { ulid } from 'ulid';
import { UserModel } from '@admin/modules/users/models/user.model';
import {
    mapApiResponseToUsersModels,
} from '@admin/modules/users/models/mapper/mapApiResponse.GetUsers';
import { mapApiResponseToUserModels } from '@admin/modules/users/models/mapper/mapApiResponse.GetUser';

@Injectable({
  providedIn: 'root',
})
export class UsersService {
  constructor(private apollo: Apollo, private route: ActivatedRoute, private toast: ToastService) {}

  addUser(user: any): Observable<any> {

    const _user = {
      ...user,
      tenantId: this.route.snapshot.queryParamMap.get('tenant'),
      id: ulid()
    };
    return this.apollo
      .mutate({
        mutation: ADD_USER,
        variables: {
          input: _user,
        },
      })
      .pipe(
        map((res: any) => {
          if (res.errors?.length) {
            return res
          } else {
            return res.data.createUser;
          }
        }),
      );
  }

  editUser(user: any): Observable<any> {
    return this.apollo
      .mutate({
        mutation: EDIT_USER,
        variables: {
          input: user,
        },
      })
      .pipe(
        map((res: any) => {
          if (res.errors?.length) {
           return res
          } else {
            return res.data.updateUser;
          }
        }),
      );
  }

  getUsers(): Observable<UserModel[]> {
    return this.apollo
      .query({
        query: GET_USERS,
        variables: {
          tenantId: this.route.snapshot.queryParamMap.get('tenant'),
        },
      })
      .pipe(
        map((res: any) => {
          if (res.errors?.length) {
            this.toast.showError('Error', res.errors[0].message);
            return []
          } else {return mapApiResponseToUsersModels(res.data?.users);
          }
        }),
      );
  }

  getUser(id?: any): Observable<UserModel> {
    return this.apollo
      .query({
        query: GET_USER,
        variables: {
          id: id ? id : this.route.snapshot.params['id'],
        },
      })
        .pipe(
            map((res: any) => {
                if (res.errors?.length) {
                    throw new Error(res.errors[0].message);
                } else {
                    return mapApiResponseToUserModels(res.data?.user);
                }
            })
        );
  }

  deleteUser(userId: string): Observable<any> {
    return this.apollo
      .mutate({
        mutation: DELETE_USER,
        variables: {
          id: userId,
        },
      })
      .pipe(
        map((res: any) => {
          return res;
        }),
      );
  }
}
