<div class="flex flex-column gap-2 node-draggable" id="node-inspector" cdkDropList (cdkDropListDropped)="drop($event)">
   <app-fieldset-p [icon]="'pi pi-stopwatch'" [header]="'DATA.MODULES.CAMPAINGS.WORKFLOWS.NODE_TYPE.WAITS' | translate">
      <div *ngFor="let node of draggables.events" id="{{ node.id }}" class="{{ node.class }}" cdkDrag>
         {{ node.name }}
      </div>
   </app-fieldset-p>

   <app-fieldset-p
      [icon]="'pi pi-check-circle'"
      [header]="'DATA.MODULES.CAMPAINGS.WORKFLOWS.NODE_TYPE.NOTIFICATIONS' | translate"
   >
      <div *ngFor="let node of draggables.actions" id="{{ node.id }}" class="{{ node.class }}" cdkDrag>
         {{ node.name }}
      </div>
      <div *ngFor="let node of draggables.actionPlus" id="{{ node.id }}" class="{{ node.class }}" cdkDrag>
         <span [class]="node.id.includes('Email') ? 'tag tag__email' : 'tag tag__sms'">IA</span>
         {{ node.name }}
      </div>
   </app-fieldset-p>

   <app-fieldset-p
      [icon]="'pi pi-sitemap'"
      [header]="'DATA.MODULES.CAMPAINGS.WORKFLOWS.NODE_TYPE.CONDITIONAL' | translate"
   >
      <svg
         class="hover-cursor"
         *ngFor="let node of draggables.conditions"
         id="{{ node.id }}"
         width="140"
         height="90"
         cdkDrag
      >
         <g *ngIf="node.id === 'paylinkNotificationStatus'">
            <polygon points="25,45 75,20 125,45 75,70" fill="transparent" stroke="#ff851b" stroke-width="5" />
            <text
               x="75"
               y="50"
               font-family="Poppins"
               font-size="8"
               text-anchor="middle"
               fill="#ff851b"
               font-weight="bold"
            >
               {{ node.name }}
            </text>
         </g>
         <g *ngIf="node.id === 'customerCaseSegments'">
            <polygon points="25,45 75,20 125,45 75,70" fill="transparent" stroke="#A67B5B" stroke-width="5" />
            <text
               x="75"
               y="50"
               font-family="Poppins"
               font-size="8"
               text-anchor="middle"
               fill="#A67B5B"
               font-weight="bold"
            >
               {{ node.name }}
            </text>
         </g>
         <g *ngIf="node.id === 'experiment'">
            <polygon points="25,45 75,20 125,45 75,70" fill="transparent" stroke="#891e35" stroke-width="5" />
            <text
               x="75"
               y="50"
               font-family="Poppins"
               font-size="8"
               text-anchor="middle"
               fill="#891e35"
               font-weight="bold"
            >
               {{ node.name }}
            </text>
         </g>
      </svg>
   </app-fieldset-p>

   <app-fieldset-p
      [icon]="'pi pi-th-large'"
      [header]="'DATA.MODULES.CAMPAINGS.WORKFLOWS.NODE_TYPE.FLEX_OPTIONS' | translate"
   >
      <svg
         class="hover-cursor"
         *ngFor="let node of draggables.flexibility"
         id="{{ node.id }}"
         width="140"
         height="100"
         cdkDrag
      >
         <g *ngIf="node.id === 'offerPartialPayment'">
            <polygon
               points="75,10 115,30 115,70 75,90 35,70 35,30"
               fill="transparent"
               stroke="#9683EC"
               stroke-width="5"
            />
            <text
               x="75"
               y="55"
               font-family="Poppins"
               font-size="10"
               text-anchor="middle"
               fill="#9683EC"
               font-weight="bold"
            >
               <tspan x="75" dy="-10">
                  {{ 'DATA.MODULES.CAMPAINGS.WORKFLOWS.NODE_TYPE.OFFER_PARTIAL_LINE_1' | translate }}
               </tspan>
               <tspan x="75" dy="15">
                  {{ 'DATA.MODULES.CAMPAINGS.WORKFLOWS.NODE_TYPE.OFFER_PARTIAL_LINE_2' | translate }}
               </tspan>
            </text>
         </g>

         <g *ngIf="node.id === 'offerPaymentAgreement'">
            <polygon points="75,90 115,50 95,10 55,10 35,50" fill="transparent" stroke="indigo" stroke-width="5" />
            <text
               x="55"
               y="30"
               font-family="Poppins"
               font-size="10"
               text-anchor="middle"
               fill="indigo"
               font-weight="bold"
            >
               <tspan x="75" dy="1.2em">
                  {{ 'DATA.MODULES.CAMPAINGS.WORKFLOWS.NODE_TYPE.OFFER_PAYMENT_AGREEMENT_LINE1' | translate }}
               </tspan>
               <tspan x="75" dy="1.2em">
                  {{ 'DATA.MODULES.CAMPAINGS.WORKFLOWS.NODE_TYPE.OFFER_PAYMENT_AGREEMENT_LINE2' | translate }}
               </tspan>
            </text>
         </g>
      </svg>
   </app-fieldset-p>

   <app-fieldset-p
      [icon]="'pi pi-phone'"
      [header]="'DATA.MODULES.CAMPAINGS.WORKFLOWS.NODE_TYPE.CALL_OPTIONS' | translate"
   >
      <div *ngFor="let node of draggables.calls" id="{{ node.id }}" class="{{ node.class }}" cdkDrag>
         {{ node.name }}
      </div>
   </app-fieldset-p>

   <app-fieldset-p [icon]="'pi pi-flag'" [header]="'DATA.MODULES.CAMPAINGS.WORKFLOWS.NODE_TYPE.END' | translate">
      <div *ngFor="let node of draggables.end" id="{{ node.id }}" class="{{ node.class }}" cdkDrag>
         {{ node.name }}
      </div>
      <div *ngFor="let node of draggables.assign" id="{{ node.id }}" class="{{ node.class }}" cdkDrag>
         {{ node.name }}
      </div>
   </app-fieldset-p>
</div>
