import { OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { CurrentUserService } from '@core/services/currentUser.service';
import { MenuService } from '../service/app.menu.service';
import { PermissionType } from '@admin/modules/users/models/user.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-menu',
  templateUrl: './app.menu.component.html',
})
export class AppMenuComponent implements OnInit {
  constructor(
    private currentUser: CurrentUserService,
    private menuService: MenuService,
    private translate: TranslateService,
  ) {}
  model: MenuItem[] = [];

  ngOnInit() {
    this.model = [
      {
        items: [
          {
            label: this.translate.instant('MENU.PANEL'),
            icon: 'pi pi-th-large',
            routerLink: ['/'],
            queryParamsHandling: 'preserve',
          },
          {
            label: this.translate.instant('MENU.CUSTOMER_JOURNEY'),
            icon: 'pi pi-map',
            routerLink: ['/customer-journey'],
            queryParamsHandling: 'preserve',
          },
          {
            label: this.translate.instant('MENU.BEHAVIORAL_PILL'),
            icon: 'pi pi-bolt',
            routerLink: ['/behavioral-pill'],
            queryParamsHandling: 'preserve',
          },
        ],
      },
      {
        label: this.translate.instant('MENU.OPERATIVA'),
        items: [
          {
            label: this.translate.instant('MENU.CLIENTS'),
            icon: 'pi pi-users pi-align-left',
            routerLink: ['/customers'],
            queryParamsHandling: 'preserve',
          },
          {
            label: this.translate.instant('MENU.INSTANCES'),
            icon: 'pi pi-folder',
            routerLink: ['/instances'],
            queryParamsHandling: 'preserve',
          },
          {
            label: this.translate.instant('MENU.OPERATIONS'),
            icon: 'pi pi-file',
            routerLink: ['/operations'],
            queryParamsHandling: 'preserve',
          },
          {
            label: this.translate.instant('MENU.PAYMENT_AGREEMENTS'),
            icon: 'pi pi-money-bill',
            routerLink: ['/payment-agreement'],
            queryParamsHandling: 'preserve',
          },
        ],
      },
      {
        id: 'campaignsAdmin',
        label: this.translate.instant('MENU.CAMPAIGNS_MANAGER'),
        items: [
          {
            label: this.translate.instant('MENU.CAMPAIGNS'),
            icon: 'pi pi-sitemap',
            routerLink: ['/campaigns'],
            queryParamsHandling: 'preserve',
          },
          {
            label: this.translate.instant('MENU.PORTFOLIOS'),
            icon: 'pi pi-folder-open',
            routerLink: ['/portfolios'],
            queryParamsHandling: 'preserve',
          },
          {
            label: this.translate.instant('MENU.TEMPLATES'),
            icon: 'pi pi-comment pi-align-left',
            routerLink: ['/copy/'],
            queryParamsHandling: 'preserve',
          },
          {
            label: this.translate.instant('MENU.SEGMENTS'),
            icon: 'pi pi-chart-pie',
            routerLink: ['/segments'],
            queryParamsHandling: 'preserve',
          },
        ],
      },
      {
        separator: true,
      },
      {
        id: 'admin',
        label: this.translate.instant('MENU.ADMINISTRATION'),
        queryParamsHandling: 'preserve',
        items: [
          {
            id: 'admin',
            label: this.translate.instant('MENU.CONFIGURATION'),
            icon: 'pi pi-user',
            queryParamsHandling: 'preserve',
            items: [
              {
                label: this.translate.instant('MENU.COLLECTOR_PROFILES'),
                icon: 'pi pi-box',
                routerLink: ['/collectors'],
                queryParamsHandling: 'preserve',
              },
              {
                label: this.translate.instant('MENU.BRANDS'),
                icon: 'pi pi-tag',
                routerLink: ['/brands'],
                queryParamsHandling: 'preserve',
              },
              {
                label: this.translate.instant('MENU.NOTIFICATION_CHANNELS'),
                icon: 'pi pi-send',
                routerLink: ['/channel-configs'],
                queryParamsHandling: 'preserve',
              },
              {
                label: this.translate.instant('MENU.FEEDBACK_OPTIONS'),
                icon: 'pi pi-arrow-right-arrow-left',
                routerLink: ['/feedback-configs'],
                queryParamsHandling: 'preserve',
              },
              {
                label: this.translate.instant('MENU.PAYMENT_METHODS'),
                icon: 'pi pi-money-bill',
                routerLink: ['/payment-methods'],
                queryParamsHandling: 'preserve',
              },
              {
                label: this.translate.instant('MENU.USERS'),
                icon: 'pi pi-user-plus',
                routerLink: ['/users'],
                queryParamsHandling: 'preserve',
              },
              {
                label: this.translate.instant('MENU.TAX_ENTITIES'),
                icon: 'pi pi-percentage',
                routerLink: ['/tax-entity'],
                queryParamsHandling: 'preserve',
              },
              {
                label: this.translate.instant('MENU.SERVICES'),
                icon: 'pi pi-briefcase',
                routerLink: ['/services'],
                queryParamsHandling: 'preserve',
              },
              {
                label: this.translate.instant('MENU.REGIONAL_CONFIGURATION'),
                icon: 'pi pi-map-marker',
                routerLink: ['/regional-configs'],
                queryParamsHandling: 'preserve',
              },
              {
                label: this.translate.instant('MENU.DATA_SHARING'),
                icon: 'pi pi-chart-line',
                routerLink: ['/event-hooks'],
                queryParamsHandling: 'preserve',
              },
              {
                label: this.translate.instant('MENU.PHASES'),
                icon: 'pi pi-code',
                routerLink: ['/phases'],
                queryParamsHandling: 'preserve',
              },
            ],
          },
          {
            separator: true,
          },
        ],
      },
    ];

    this.allowedFullItems();
    this.allowAdminItems();
  }

  isAllow(permissions: PermissionType[]): boolean {
    return this.currentUser.allowAccessPermission([...permissions]);
  }

  allowAdminItems() {
    const allowedPermissions = [
      PermissionType.FULL_ACCESS,
      PermissionType.ADMINISTRATION,
      PermissionType.COPY_READ,
    ];
    let restrictedItemIds = ['Plantillas', 'Gestor de campañas'];

    if (!this.isAllow(allowedPermissions)) {
      this.model = this.menuService.removeMenuItems(this.model, restrictedItemIds);
      return;
    }
  }

  allowedFullItems() {
    const allowedPermissions = [PermissionType.FULL_ACCESS];
    let restrictedItemIds = ['Configuración', 'Customer Journey'];

    if (!this.isAllow(allowedPermissions)) {
      this.model = this.menuService.removeMenuItems(this.model, restrictedItemIds);
      return;
    }
  }
}
