import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { inputTimezone } from '@layout/profile-sidebar/config/inputs';
import { convertTimezones } from '@shared/helpers/convertTimezone';
import { tiomezoneListPHP } from '@shared/helpers/variables';
import { DynamicFormComponent } from '@shared/components/dynamic-form/dynamic-form.component';
import { LayoutService } from '@layout/service/app.layout.service';
import { TimezoneService } from '@shared/services/timezone.service';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-selector-timezone',
  templateUrl: './selector-timezone.component.html',
  styleUrls: ['./selector-timezone.component.scss'],
})
export class SelectorTimezoneComponent implements OnInit, AfterViewInit {
  @ViewChild('form') form!: DynamicFormComponent;

  selectedTimezone: string = '';
  oldSelectedTimezone: string = '';

  protected readonly inputForm = inputTimezone;

  constructor(
    public layoutService: LayoutService,
    public timezoneService: TimezoneService,
    public dialogRef: DynamicDialogRef,
    public translateService: TranslateService,
  ) {}

  ngOnInit() {
    this.inputForm[0].label = this.translateService.instant('DATA.COMMON.HUSO.BUTTON_TEXT');
  }

  ngAfterViewInit() {
    this.setOptionsTimezone();
  }

  onTimezoneChange(timezone: any): void {
    const tzName = timezone.timezone;
    const gmtOffset = this.getGMTOffset(tzName);
    this.selectedTimezone = `${tzName} (GMT${gmtOffset})`;
  }

  confirmChange() {
    this.oldSelectedTimezone = this.selectedTimezone;
    this.timezoneService.setTimezone(this.selectedTimezone);
    this.closeDialog();
    window.location.reload();
  }

  setOptionsTimezone() {
    this.selectedTimezone = this.timezoneService.getTimezone();

    if (this.form && this.form.formFields)
      this.form.formFields = this.form.formFields.map((field) => {
        if (field.key === 'timezone') {
          field.options = convertTimezones(tiomezoneListPHP);

          const timezone = this.selectedTimezone || 'UTC';

          const timezoneOnly = timezone.split(' ')[0];

          field.setPropertyValue('value', timezoneOnly);
        }
        return field;
      });
  }

  getGMTOffset(timezone: string): string {
    const now = new Date();
    const formatter = new Intl.DateTimeFormat('en-US', {
      timeZone: timezone,
      timeZoneName: 'shortOffset',
    });

    const parts = formatter.formatToParts(now);
    const gmtPart = parts.find((part) => part.type === 'timeZoneName');

    return gmtPart ? gmtPart.value.replace('GMT', '') : '+0';
  }

  closeDialog() {
    this.dialogRef.close({});
  }
}
