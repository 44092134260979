import { IWorkflow } from '@modules/campaigns/Domain/Interfaces/IWorkflow';
import { WorkflowType } from '@modules/campaigns/Domain/Enums/WorkflowType';
import { ValidationError } from '@modules/campaigns/Domain/Enums/ValidationErrors';
import { NodeType } from '@modules/campaigns/Domain/Enums/NodeType';
import { NodeClass } from '@modules/campaigns/Domain/NodeClass';
import { IWorkflowValidator } from '@modules/campaigns/Domain/Interfaces/IWorkflowValidator';

export class AllNodesConnectedToEndValidator implements IWorkflowValidator {
  validate(workflow: IWorkflow): string[] {
    const errors: string[] = [];
    const endNodes = workflow.nodes.filter((node) => node.type === NodeType.End);

    const checkNodeConnectionToEnd = (node: NodeClass): boolean => {
      if (node.type === NodeType.End || node.type === NodeType.AssignCampaign) return true;
      return node.childrenNodes.some((childId) => {
        const childNode = workflow.getNode(childId);
        return childNode && checkNodeConnectionToEnd(childNode);
      });
    };

    if (
      !workflow.nodes.every(
        (node) =>
          node.type === NodeType.End ||
          node.type === NodeType.AssignCampaign ||
          checkNodeConnectionToEnd(node),
      )
    ) {
      errors.push('NOT_CONNECTED_TO_END');
    }

    return errors;
  }

  shouldValidate(workflow: IWorkflow): boolean {
    return workflow.type === WorkflowType.DRIP || workflow.type === WorkflowType.PILL;
  }
}
