import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { StandarDatePaymefyPipe } from '@shared/pipes/standar-paymefy-pipe.pipe';
import { hascopyIds } from '@modules/campaigns/Domain/Factories/HasNodeConfig';
import { NodeClass } from '@modules/campaigns/Domain/NodeClass';
import { GetCopyService } from '@shared/components/joint-js/services/get-copies';
import { forkJoin, map, Observable, of } from 'rxjs';
import { ToastService } from '@services/ui';
import { catchError } from 'rxjs/operators';
import { translateDay } from '@shared/components/joint-js/config/constants';
import { NodeType } from '@modules/campaigns/Domain/Enums/NodeType';
import { TransformDateTimezonePipe } from '@shared/pipes/transformDateTimezone.pipe';
import { SegmentationService } from '@modules/segments/services/segments.service';

@Injectable({
  providedIn: 'root',
})
export class MonitoringTooltip {
  constructor(
    private timeZoneDate: TransformDateTimezonePipe,
    private translate: TranslateService,
    private getConfigService: GetCopyService,
    private segmentService: SegmentationService,
    private toast: ToastService,
  ) {}

  createMonitoringTemplate(node: NodeClass, extraParameters: any | undefined): Observable<string> {
    if (hascopyIds(node.config)) {
      const copyObservables = node.config['copyIds'].map((copyId) =>
        this.getConfigService.getCopy(copyId).pipe(
          map((data: any) => {
            if (data && data.errors) {
              this.toast.showError('Error', `${data.errors[0].message}`);
              return null;
            }
            return data.data.copy.name;
          }),
          catchError((error) => {
            throw Error(error.message);
          }),
        ),
      );

      return forkJoin(copyObservables).pipe(
        map((responses: (string | null)[]) => {
          const copies = responses.filter((name) => name !== null);
          return `[${copies.join(', ')}]`;
        }),
      );
    } else if (
      node.type !== NodeType.Conditional &&
      node.type !== NodeType.Experiment &&
      node.type !== NodeType.ConditionalSegments &&
      node.type !== NodeType.OfferPaymentAgreement &&
      node.type !== NodeType.OfferPartialPayment &&
      node.type !== NodeType.MakeOutBoundCall
    ) {
      const result = this.buildTemplateString(
        this.getPartDay(node),
        !this.isStartOrEnd(node.type)
          ? this.translate.instant(
              'DATA.MODULES.CAMPAINGS.ELEMENTS.NODES.PARAMETERS.NAME.' + node.type,
            ) + this.getWaitDays(node)
          : '',
        this.createScheduleString(extraParameters.firstSchedule, extraParameters.lastSchedule),
        this.createCustomerCasesString(extraParameters.customerCasesCount),
      );
      return of(result);
    } else if (node.type == NodeType.ConditionalSegments) {
      if (node && node.config && node.config['segmentIds'].length > 0) {
        return this.segmentService.getSegment(node.config['segmentIds'][0]).pipe(
          map((segment: any) => 'Segment: ' + segment.name),
          catchError(() => of('No segment')),
        );
      } else {
        return of('No segment');
      }
    } else if (node.type == NodeType.Conditional) {
      const statusesTranslated = node.config['statuses']
        .map((status: string) => this.translate.instant(status))
        .join(', ');

      const statusConditional = 'Statuses: ' + statusesTranslated;
      return of(statusConditional);
    } else if (node.type == NodeType.OfferPaymentAgreement) {
      const expirationDays =
        this.translate.instant('DATA.MODULES.CAMPAINGS.ELEMENTS.NODES.PARAMETERS.DAYS_UNTIL') +
        ' ' +
        (node.config['expirationDays'] || '') +
        ' ' +
        this.translate.instant('DATA.COMMON.TIME.DAYS');
      const numberOfInstalments =
        this.translate.instant(
          'DATA.MODULES.CAMPAINGS.ELEMENTS.NODES.PARAMETERS.NUMBER_INSTALMENTS',
        ) +
        ' ' +
        (node.config['numberOfInstalments'] || '') +
        ' ' +
        this.translate.instant('DATA.MODULES.CAMPAINGS.ELEMENTS.NODES.PARAMETERS.INSTALMENTS_IN') +
        ' ' +
        (node.config['numberOfInstalments'] || '') +
        ' ' +
        this.translate.instant('DATA.COMMON.TIME.MONTHS');

      const result = this.buildTemplateString(expirationDays, numberOfInstalments);

      return of(result);
    } else if (node.type == NodeType.OfferPartialPayment) {
      const expirationDays: string =
        this.translate.instant('DATA.MODULES.CAMPAINGS.ELEMENTS.NODES.PARAMETERS.DAYS_UNTIL') +
        ' ' +
        +(node.config['expirationDays'] || '') +
        ' ' +
        this.translate.instant('DATA.COMMON.TIME.DAYS');

      const minimumAmount =
        this.translate.instant('DATA.MODULES.CAMPAINGS.ELEMENTS.NODES.PARAMETERS.MINIMUM_AMOUNT') +
        ' ' +
        (node.config['minimumAmount'].amount || '') +
        ' ' +
        (node.config['minimumAmount'].currency || '');
      const minimumPercentageAmount =
        this.translate.instant(
          'DATA.MODULES.CAMPAINGS.ELEMENTS.NODES.PARAMETERS.MINIMUM_PERCENTAGE',
        ) +
        ' ' +
        (node.config['minimumPercentageAmount'] || 'No amount') +
        ' %';

      const result = this.buildTemplateString(
        expirationDays.toString(),
        minimumPercentageAmount.toString(),
        minimumAmount.toString(),
      );

      return of(result);
    } else if (node.type == NodeType.Experiment) {
      const percentage = 'Percentage: ' + (node.config['percentage'] || '') + ' %';

      const result = this.buildTemplateString(percentage.toString());

      return of(result);
    }

    return of('');
  }

  private buildTemplateString(...parts: string[]): string {
    return parts.filter((part) => part && part.trim() !== '').join('\n');
  }

  private getWaitDays(node: NodeClass): string {
    if (node.config['partDay'] === null || node.type === 'start' || node.type === 'end') {
      return '';
    }
    if (node.config['partDay'] != null && node.type === 'waitDays') {
      return node.config['waitDays'].toString();
    }

    if (node.config['partDay'] != null && node.type === 'waitDayOfWeek') {
      const day = translateDay(node.config['dayOfWeek']);
      return this.translate.instant(day);
    }

    if (node.config['partDay'] != null && node.type === 'waitDayOfMonth') {
      return node.config['dayOfMonth'].toString();
    }

    return '';
  }

  private isStartOrEnd(name: string): boolean {
    return name === 'start' || name === 'end' || name === 'assignCampaign';
  }

  private getPartDay(node: NodeClass): string {
    let partDay = '';

    if (node.config['partDay'] !== null && !this.isStartOrEnd(node.type)) {
      partDay = this.translate.instant(
        'DATA.MODULES.CAMPAINGS.ELEMENTS.NODES.PARAMETERS.PART_DAY.' + node.config['partDay'],
      );

      return `${this.translate.instant(
        'DATA.MODULES.CAMPAINGS.ELEMENTS.NODES.PARAMETERS.PART_DAY.OF',
      )} ${partDay}`;
    }
    return '';
  }

  private createCustomerCasesString(count: number): string {
    return count > 0
      ? ` ${this.translate.instant('DATA.MODULES.CAMPAINGS.ELEMENTS.NODES.ACTIVE_CASES.N_CASES', {
          count: count,
        })}`
      : `${this.translate.instant('DATA.MODULES.CAMPAINGS.ELEMENTS.NODES.ACTIVE_CASES.NO_CASES')}`;
  }

  private createScheduleString(
    firstSchedule: string | undefined,
    lastSchedule: string | undefined,
  ): string {
    const startDate = firstSchedule
      ? this.timeZoneDate.transform(firstSchedule)
      : this.translate.instant('DATA.MODULES.CAMPAINGS.ELEMENTS.NODES.PARAMETERS.NAME.start');
    const endDate = lastSchedule
      ? this.timeZoneDate.transform(lastSchedule)
      : this.translate.instant('DATA.MODULES.CAMPAINGS.ELEMENTS.NODES.PARAMETERS.NAME.end');

    return `${startDate} - ${endDate}`;
  }
}
