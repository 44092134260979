<h5 class="ml-1"> {{ 'DATA.MODULES.SHARED.COMPONENTS.SEND_REMINDER.TITLE' | translate }}</h5>

<div class="p-flex flex-column">
   <app-dynamic-form
      [formFields]="reminderChannel"
      [formCols]="12"
      [submit]="false"
      [reset]="false"
      btnLabel3="{{ 'DATA.MODULES.SHARED.COMPONENTS.SEND_REMINDER.FORM.ACTIONS.CONFIRM' | translate }}"
      btnLabelSave="{{ 'DATA.MODULES.SHARED.COMPONENTS.SEND_REMINDER.FORM.ACTIONS.SEND' | translate }}"
      confirmMsg="{{ 'DATA.MODULES.SHARED.COMPONENTS.SEND_REMINDER.FORM.ACTIONS.CONFIRM_MESSAGE' | translate }}"
      (formValuesChanged)="onChanges($event)"
   />

   <div class="m-2">
      <p *ngIf="this.sms">
         {{ 'DATA.MODULES.SHARED.COMPONENTS.SEND_REMINDER.PHONE_SEND_MSG' | translate }}
         <b> <p-tag [value]="this.sms" /> </b>
      </p>
      <p *ngIf="this.email">
         {{ 'DATA.MODULES.SHARED.COMPONENTS.SEND_REMINDER.EMAIL_SEND_MSG' | translate }}
         <b> <p-tag [value]="this.email" /> </b>
      </p>
   </div>

   <div class="flex flex-row-reverse">
      <p-button
         [disabled]="!this.sms && !this.email"
         (onClick)="send()"
         [label]="'DATA.MODULES.SHARED.COMPONENTS.SEND_REMINDER.FORM.ACTIONS.SEND' | translate"
      ></p-button>
   </div>
</div>
