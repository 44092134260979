import { Injectable } from '@angular/core';
import { Observable, map, BehaviorSubject, catchError, throwError, of } from 'rxjs';
import {
  EXPORT_PAYMENT_AGREEMENTS_LIST,
  GET_PAYMENT_AGREEMENTS,
} from '../graphql/payment-agreement.graphql';
import { ActivatedRoute } from '@angular/router';
import { CurrentUserService } from '@core/services/currentUser.service';
import { Apollo } from 'apollo-angular';
import { ulid } from 'ulid';
import {
  CALCULATE_INSTALMENTS,
  CREATE_PARTIAL_PAYMENT_OFFER,
  SEND_PROPOSE_PAYMENT_AGREEMENT,
  VALIDATE_DEPENDENCIES,
} from '@modules/customers/customers.GraphQL';
import { TranslateService } from '@ngx-translate/core';
import { FormDropdown, FormNumber, FormTextbox } from '@shared/components/dynamic-form/models';
import { maxSafeIntegerValidator } from '@shared/validators/forms/maxSafeInteger';
import { Validators } from '@angular/forms';
import { nodeSendTypes } from '@shared/helpers/variables';

@Injectable({
  providedIn: 'root',
})
export class PaymentAgreementService {
  isLoading$ = new BehaviorSubject<boolean>(false);

  constructor(
    private apollo: Apollo,
    private route: ActivatedRoute,
    private currentUser: CurrentUserService,
    private translate: TranslateService,
  ) {}

  getPaymentAgreementList(
    params: { offset: number; limit: number },
    filters?: any,
  ): Observable<any> {
    filters = filters
      ? { ...filters, tenantId: this.route.snapshot.queryParamMap.get('tenant') }
      : {
          tenantId: this.route.snapshot.queryParamMap.get('tenant'),
          searchPattern: '',
          status: [],
          collectorIds: this.currentUser.currentUser.collectorIds,
        };

    if (filters.collectorIds.length === 0) {
      filters.collectorIds = this.currentUser.currentUser.collectorIds;
    }

    return this.apollo.query<any>({
      query: GET_PAYMENT_AGREEMENTS,
      variables: {
        criteria: {
          pagination: {
            offset: params.offset,
            limit: params.limit,
          },
          filters,
        },
      },
    });
  }

  exportPaymentAgreementsList(filters?: any): Observable<string> {
    filters = filters
      ? {
          ...filters,
          tenantId: this.route.snapshot.queryParamMap.get('tenant'),
          collectorIds: filters.collectorIds
            ? filters.collectorIds
            : this.currentUser.currentUser.collectorIds,
        }
      : {
          tenantId: this.route.snapshot.queryParamMap.get('tenant'),
          searchPattern: '',
          status: [],
          collectorIds: this.currentUser.currentUser.collectorIds,
        };

    return this.apollo
      .query<any>({
        query: EXPORT_PAYMENT_AGREEMENTS_LIST,
        variables: {
          fileId: ulid(),
          criteria: {
            filters,
          },
        },
      })
      .pipe(
        map((res) => {
          if (res.errors?.length) {
            throw new Error(res.errors[0].message);
          }
          return res.data.exportPaymentAgreementList.url;
        }),
        catchError((error) => throwError(() => new Error(error))),
      );
  }

  calculatePaymentAgreementInstalments(
    customerCaseId: string,
    numberOfInstalments: number,
  ): Observable<any> {
    const queryVariables = {
      customerCaseId,
      numberOfInstalments,
    };
    return this.apollo
      .query<any>({
        query: CALCULATE_INSTALMENTS,
        variables: queryVariables,
      })
      .pipe(
        map((response) => response.data.calculatePaymentAgreementInstalments),
        catchError((error) => {
          return throwError(() => new Error(error));
        }),
      );
  }

  checkvalidateDependencies(customerCaseId: string): Observable<any> {
    return this.apollo
      .query<any>({
        query: VALIDATE_DEPENDENCIES,
        variables: {
          customerCaseId,
        },
      })
      .pipe(
        map((res) => {
          if (res.errors?.length) {
            throw res;
          } else {
            const { validatePaymentAgreementsDependencies: v } = res.data;
            return v;
          }
        }),
        catchError(() => {
          return of([]);
        }),
      );
  }

  sendProposePaymentAgreement(
    customerId: string,
    payment: { channel: string; nMonths: number },
  ): Observable<any> {
    return this.apollo
      .mutate({
        mutation: SEND_PROPOSE_PAYMENT_AGREEMENT,
        variables: {
          input: {
            id: ulid(),
            tenantId: this.route.snapshot.queryParamMap.get('tenant'),
            customerCaseId: customerId,
            numberOfInstalments: payment.nMonths,
            channel: payment.channel,
          },
        },
      })
      .pipe(map((res: any) => res));
  }

  sendPartialPaymentOffer(
    customerId: string,
    selectedCopyId: string,
    expirationAt: Date,
    currency: string,
    minimumAbsoluteAmount: number,
    minimumPercentageAmount: number,
  ): Observable<any> {
    return this.apollo
      .mutate({
        mutation: CREATE_PARTIAL_PAYMENT_OFFER,
        variables: {
          input: {
            id: ulid(),
            tenantId: this.route.snapshot.queryParamMap.get('tenant'),
            copyId: selectedCopyId,
            customerCaseId: customerId,
            currency: currency,
            expirationAt: expirationAt,
            minimumAbsoluteAmount: minimumAbsoluteAmount * 100,
            minimumPercentageAmount: minimumPercentageAmount == 0 ? null : minimumPercentageAmount,
          },
        },
      })
      .pipe(map((res: any) => res.data.createPartialPaymentOffer));
  }

  getSearchInputs() {
    return [
      new FormDropdown({
        key: 'collectorIds',
        type: 'dropdown',
        required: false,
        label: this.translate.instant('DATA.MODULES.PAYMENT_AGREEMENT.FORM.PROFILE_OF_COLLECTION'), // Using translate.instant
        value: '',
        options: [],
        multi: true,
        filter: true,
        readonly: false,
      }),

      new FormDropdown({
        key: 'status',
        type: 'dropdown',
        required: false,
        label: this.translate.instant('DATA.MODULES.PAYMENT_AGREEMENT.FORM.STATUS'),
        value: [],
        options: [
          {
            label: this.translate.instant('DATA.MODULES.PAYMENT_AGREEMENT.FORM.BROKEN'),
            value: 'broken',
          },
          {
            label: this.translate.instant('DATA.MODULES.PAYMENT_AGREEMENT.FORM.FULFILLED'),
            value: 'fulfilled',
          },
          {
            label: this.translate.instant('DATA.MODULES.PAYMENT_AGREEMENT.FORM.EXPIRED'),
            value: 'expired',
          },
          {
            label: this.translate.instant('DATA.MODULES.PAYMENT_AGREEMENT.FORM.PROPOSAL'),
            value: 'proposal',
          },
          {
            label: this.translate.instant('DATA.MODULES.PAYMENT_AGREEMENT.FORM.SIGNED'),
            value: 'signed',
          },
        ],
        multi: true,
        filter: true,
        readonly: false,
      }),

      new FormTextbox({
        key: 'searchPattern',
        required: false,
        label: this.translate.instant('DATA.MODULES.PAYMENT_AGREEMENT.FORM.SEARCH'),
        value: '',
        readonly: false,
      }),
    ];
  }

  getPartialPaymentOffer() {
    return [
      new FormNumber({
        key: 'minimumAbsoluteAmount',
        type: 'number',
        required: true,
        label: 'DATA.MODULES.SHARED.COMPONENTS.PARTIAL_PAYMENT.FORM.MIN_AMOUNT',
        value: 1,
        placeholder: '',
        readonly: false,
        inputGroup: true,
        addon: 'EUR',
        validators: [maxSafeIntegerValidator()],
      }),

      new FormNumber({
        key: 'minimumPercentageAmount',
        type: 'number',
        required: true,
        label: 'DATA.MODULES.SHARED.COMPONENTS.PARTIAL_PAYMENT.FORM.MIN_AMOUNT_PERCENT',
        value: 1,
        placeholder: '',
        readonly: false,
        inputGroup: true,
        isVisible: false,
        addon: '%',
        validators: [Validators.pattern('^(\\d|[1-9]\\d|99)$'), Validators.min(1)],
      }),

      new FormDropdown({
        key: 'name',
        type: 'dropdown',
        required: false,
        label: 'DATA.MODULES.SHARED.COMPONENTS.PARTIAL_PAYMENT.FORM.CHANNEL',
        value: this.translate.instant(
          'DATA.MODULES.CAMPAINGS.ELEMENTS.NODES.PARAMETERS.NAME.' + nodeSendTypes[0].value,
        ),
        options: this.translateOptions(
          nodeSendTypes,
          'DATA.MODULES.CAMPAINGS.ELEMENTS.NODES.PARAMETERS.NAME.',
        ),
        multi: false,
        filter: false,
        readonly: false,
        disabled: false,
      }),

      new FormDropdown({
        key: 'copyIds',
        type: 'dropdown',
        required: true,
        label: 'DATA.MODULES.SHARED.COMPONENTS.PARTIAL_PAYMENT.FORM.TEMPLATE',
        value: null,
        options: [],
        multi: false,
        selectionLimit: 1000,
        filter: true,
        readonly: false,
        isVisible: false,
      }),
    ];
  }

  private translateOptions(options: any[], key: string): any[] {
    return options.map((option) => ({
      ...option,
      label: this.translate.instant(key + option.value),
    }));
  }
}
