import { NodeClass } from '@modules/campaigns/Domain/NodeClass';
import { NodeType } from '@modules/campaigns/Domain/Enums/NodeType';
import {
  ConditionalConfig,
  ConditionalSegmentConfig,
} from '@modules/campaigns/Domain/Interfaces/INodeConfig';
import {
  getValidationErrorMessage,
  ValidationError,
} from '@modules/campaigns/Domain/Enums/ValidationErrors';
import { NodeValidationError } from '@modules/campaigns/Domain/Interfaces/NodeValidationError';

export class ConditionalSegmentNode extends NodeClass {
  constructor(id: string, position: { x: number; y: number }, config: ConditionalSegmentConfig) {
    super(id, NodeType.ConditionalSegments, position, config);
  }

  isEmptyConfig(): boolean {
    return this.config['segmentIds'] == null || this.config['segmentIds'][0] == null;
  }

  addChild(node: string): void {
    if (this.childrenNodes.length === 0 || 1) this.childrenNodes.push(node);
    else console.warn('TENEMOS CHILD');
  }

  addParent(node: string): void {
    if (this.parentNodes.length === 0) this.parentNodes.push(node);
    else console.warn('TENEMOS PARENT');
  }

  initNodeConfig() {
    (this.config as ConditionalSegmentConfig).segmentIds = [];
    (this.config as ConditionalSegmentConfig).cases = { false: null, true: null };
  }

  validate(): NodeValidationError[] {
    const errors: NodeValidationError[] = [];
    if (this.config['segmentIds'].length === 0) {
      /*const errorMessage = getValidationErrorMessage(ValidationError.NO_SEGMENTS, {
        nodoType: 'Condicional de Segmentación',
      });*/
      errors.push({ nodeId: this.id, errorMessage: ValidationError.NO_SEGMENTS });
    }

    if (this.config['cases'].false == null || this.config['cases'].true == null) {
      /*const errorMessage = getValidationErrorMessage(ValidationError.NO_CASE_CONDITIONAL, {
  nodoType: 'Condicional de Segmentación',
});*/
      +errors.push({ nodeId: this.id, errorMessage: ValidationError.NO_CASE_CONDITIONAL });
    }

    return errors;
  }
}
