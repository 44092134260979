<div class="p-3">
   <div *ngIf="selectedNode?.type === NodeType.WaitDaysForDueDate && selectedNode">
      <p-messages severity="info" [closable]="true">
         <ng-template pTemplate>
            <div class="p-message flex flex-row gap-2">
               <span class="pi pi-info-circle p-1"></span>
               <span
                  [innerHTML]="
                     'DATA.MODULES.CAMPAINGS.WORKFLOWS.WAIT.INFO_WAIT_DAYS_FOR_DUE_DATE'
                        | translate
                           : {
                                partDay: getPartDay(),
                                waitDays: getWaitDays(),
                                beforeOrAfter: selectedNode.config['antesODespues'] ? (selectedNode && selectedNode.config['antesODespues'] | translate) : '-'
                             }
                  "
               ></span>
            </div>
         </ng-template>
      </p-messages>
   </div>

   <div *ngIf="selectedNode?.type === NodeType.WaitDays && selectedNode">
      <p-messages severity="info" [closable]="true">
         <ng-template pTemplate>
            <div class="p-message flex flex-row gap-2">
               <span class="pi pi-info-circle p-1"></span>
               <span
                  [innerHTML]="
                     'DATA.MODULES.CAMPAINGS.WORKFLOWS.WAIT.INFO_WAIT_DAYS'
                        | translate: { partDay: getPartDay(), waitDays: getWaitDays() }
                  "
               ></span>
            </div>
         </ng-template>
      </p-messages>
   </div>

   <div *ngIf="selectedNode?.type === NodeType.WaitDayOfWeek && selectedNode">
      <p-messages severity="info" [closable]="true">
         <ng-template pTemplate>
            <div class="p-message flex flex-row gap-2">
               <span class="pi pi-info-circle p-1"></span>
               <span
                  [innerHTML]="
                     'DATA.MODULES.CAMPAINGS.WORKFLOWS.WAIT.INFO_WAIT_DAY_OF_WEEK'
                        | translate: { partDay: getPartDay(), dayOfWeek: getDayOfWeek() }
                  "
               ></span>
            </div>
         </ng-template>
      </p-messages>
   </div>

   <div *ngIf="selectedNode?.type === NodeType.WaitDayOfMonth && selectedNode">
      <p-messages severity="info" [closable]="true">
         <ng-template pTemplate>
            <div class="p-message flex flex-row gap-2">
               <span class="pi pi-info-circle p-1"></span>
               <span
                  [innerHTML]="
                     'DATA.MODULES.CAMPAINGS.WORKFLOWS.WAIT.INFO_WAIT_DAY_OF_MONTH'
                        | translate: { partDay: getPartDay(), dayOfMonth: getDayOfMonth() }
                  "
               ></span>
            </div>
         </ng-template>
      </p-messages>
   </div>
</div>
