<div class="layout-topbar">
   <div class="flex gap-2">
      <button #menubutton type="button" class="topbar-menubutton p-link p-trigger mr-2" (click)="onMenuButtonClick()">
         <i class="pi pi-chevron-right"></i>
      </button>
      <p-menubar [model]="itemsStart"> </p-menubar>
   </div>

   <div class="topbar-end flex gap-3 align-items-center">
      <p-chip
         [label]="selectedTimezone"
         icon="pi pi-hourglass"
         [pTooltip]="'DATA.COMMON.HUSO.TOOLTIP' | translate"
         [tooltipPosition]="'bottom'"
         (click)="showTimezone()"
         [ngStyle]="{ cursor: 'pointer' }"
      ></p-chip>
      <p-button
         *ngIf="isAllow([PERMISSION_TYPE.FULL_ACCESS])"
         [icon]="loading ? 'pi pi-spin pi-spinner' : 'pi pi-server'"
         [routerLink]="['/tenants']"
         [queryParamsHandling]="'preserve'"
         [pTooltip]="!loading ? tenantName : ''"
         [tooltipPosition]="'bottom'"
         [label]="loading ? 'Cargando...' : (tenantName | ellipsis: 8)"
         styleClass="p-button-outlined p-button-secondary"
      ></p-button>
      <p-button
         *ngIf="isAllow([PERMISSION_TYPE.FULL_ACCESS, PERMISSION_TYPE.ACTIVITY])"
         [icon]="'pi pi-arrows-v'"
         label="{{ 'DATA.MODULES.LAYOUT.ACTIVITY.LABEL.ACTIVITIES' | translate }}"
         (click)="onActivityButtonClick()"
         styleClass="p-button-outlined p-button-secondary"
      ></p-button>
      <button type="button" class="p-link" (click)="onProfileButtonClick()">
         <p-avatar
            [label]="letters(user.name)"
            styleClass="w-3rem h-3rem border-circle"
            size="xlarge"
            [style]="{ 'background-color': '#c1cdd7', color: 'black' }"
            shape="circle"
         ></p-avatar>
      </button>
   </div>
</div>
