export interface UserModel {
  id: string;
  email: string;
  name: string;
  surname: string;
  language: string;
  tenantId: string;
  collectorIds: string[];
  permissions: PermissionType[];
  role: string;
  __typename: string;
}

export interface RoleModel {
  id: string;
  name: string;
  __typename: string;
}

export enum PermissionType {
  FULL_ACCESS = 'full_access',
  MANAGER = 'manager',
  ADMINISTRATION = 'administration',
  USER_ADMINISTRATION = 'user_administration',

  // New Cases
  OPERATION_READ = 'operation.read',
  OPERATION_WRITE = 'operation.write',
  OPERATION_EXPORT = 'operation.export',

  CUSTOMER_READ = 'customer.read',
  CUSTOMER_WRITE = 'customer.write',
  CUSTOMER_EXPORT = 'customer.export',

  IMPORT_READ = 'import.read',
  IMPORT_WRITE = 'import.write',
  IMPORT_COMMIT = 'import.commit',

  WORKFLOW_READ = 'workflow.read',
  WORKFLOW_WRITE = 'workflow.write',

  COPY_READ = 'copy.read',
  COPY_WRITE = 'copy.write',

  CUSTOMER_CASE_READ = 'customer_case.read',
  CUSTOMER_CASE_WRITE = 'customer_case.write',

  COLLECTOR_READ = 'collector.read',
  COLLECTOR_WRITE = 'collector.write',

  TAG_READ = 'tag.read',
  TAG_WRITE = 'tag.write',

  USER_READ = 'user.read',
  USER_WRITE = 'user.write',

  DASHBOARD = 'dashboard',
  REPORTS = 'reports',

  CONVERSATIONS = 'conversations',

  ACTIVITY = 'activity',

  CAMPAIGN_CALENDAR_QUERIES = 'campaign_calendar_queries',

  BUSINESS_INSIGHT = 'business_insight',

  SEGMENT_WRITE = 'segment.write',
  SEGMENT_READ = 'segment.read',

  PORTFOLIO_WRITE = 'portfolio.write',
  PORTFOLIO_READ = 'portfolio.read',

  LISTING_WRITE = 'listing.write',
}
