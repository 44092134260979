<app-fieldset-p
   [icon]="'pi pi-wrench'"
   [header]="'DATA.MODULES.CAMPAINGS.ELEMENTS.NODES.PARAMETERS.CONFIGURATION' | translate"
   class="d-flex flex-column"
   style="max-width: 300px"
>
   <div class="d-flex flex-column" style="max-width: 300px; min-width: 300px">
      <app-dynamic-form
         *ngIf="this.selectedNode"
         #nodeForm
         [formFields]="formType"
         [formCols]="12"
         (formValuesChanged)="updateNodeAttributes($event)"
         [reset]="false"
         [submit]="false"
      ></app-dynamic-form>

      <!-- CONDITIONAL SELECTOR -->
      <div
         *ngIf="this.selectedNode && this.selectedNode?.type === NodeType.Conditional"
         class="flex flex-column justify-content-start p-3 m-neg-top-3"
      >
         <p-toast></p-toast>
         <p class="m-neg-3">{{ 'DATA.MODULES.CAMPAINGS.ELEMENTS.NODES.PARAMETERS.NODE_OBJECTIVE' | translate }}</p>
         <div class="flex flex-row">
            <div *ngIf="nameGoalNode === ''" class="rectangle">
               {{ 'DATA.MODULES.CAMPAINGS.ELEMENTS.NODES.PARAMETERS.SELECT_TARGET_NODE' | translate }}
            </div>
            <div *ngIf="nameGoalNode !== ''">
               <input
                  pInputText
                  class="custom-input"
                  autocomplete="off"
                  [readonly]="true"
                  [disabled]="true"
                  [value]="nameGoalNode"
               />
            </div>
            <!--<p-speedDial
               class="ml-4"
               showIcon="pi pi-bars"
               [model]="items!"
               direction="down"
               [radius]="80"
            ></p-speedDial>-->
            <p-button [disabled]="false" (onClick)="addGoalNode()" icon="pi pi-plus" styleClass="h-3rem w-3rem ml-4" />
         </div>
      </div>

      <!-- SMS AND EMAILS -->
      <div
         class="m-3 -mt-2 panel-card"
         *ngIf="!loading && this.copiesMessage.length && this.selectedNode && hascopyIds(this.selectedNode?.config!)"
      >
         <ng-container *ngFor="let message of this.copiesMessage; let i = index" class="d-flex flex-column gap-2">
            <p-panel
               *ngIf="message && message.typeChanel === 'EMAIL'"
               class="custom-panel mb-2"
               [header]="truncateText(message.asunto)"
               [toggleable]="true"
               [collapsed]="i !== 0"
            >
               <p>
                  <b>{{ 'DATA.MODULES.CAMPAINGS.ELEMENTS.NODES.PARAMETERS.SUBJECT' | translate }}</b> <br />
                  <i>{{ message.asunto }}</i>
               </p>
               <p-divider></p-divider>
               <p>
                  <b>{{ 'DATA.MODULES.CAMPAINGS.ELEMENTS.NODES.PARAMETERS.PRE_HEADER' | translate }}</b> <br />
                  <i>{{ message.preHeader }}</i>
               </p>
               <p-divider></p-divider>
               <p>
                  <b>{{ 'DATA.MODULES.CAMPAINGS.ELEMENTS.NODES.PARAMETERS.MESSAGE' | translate }}</b> <br />
                  <i>{{ message.text }}</i>
               </p>
               <p-divider></p-divider>
               <p-chip label="{{ message.buttonText }}"></p-chip>
               <ng-template pTemplate="icons">
                  <button
                     [pTooltip]="'DATA.MODULES.CAMPAINGS.ELEMENTS.NODES.PARAMETERS.COPY_MESSAGE' | translate"
                     class="p-panel-header-icon p-link mr-2"
                     (click)="copyText(message.text)"
                  >
                     <span class="pi pi-copy"></span>
                  </button>
               </ng-template>
            </p-panel>

            <p-panel
               *ngIf="message && message.typeChanel === 'SMS'"
               class="custom-panel mb-2"
               [header]="truncateText(message.asunto)"
               [toggleable]="true"
               [collapsed]="i !== 0"
            >
               <p>
                  <b>{{ 'DATA.MODULES.CAMPAINGS.ELEMENTS.NODES.PARAMETERS.MESSAGE' | translate }}</b> <br />
                  <i>{{ message.text }}</i>
               </p>
               <ng-template pTemplate="icons">
                  <button
                     [pTooltip]="'DATA.MODULES.CAMPAINGS.ELEMENTS.NODES.PARAMETERS.COPY_MESSAGE' | translate"
                     class="p-panel-header-icon p-link mr-2"
                     (click)="copyText(message.text)"
                  >
                     <span class="pi pi-copy"></span>
                  </button>
               </ng-template>
            </p-panel>
         </ng-container>
      </div>

      <!-- WAIT NODES -->
      <app-message-wait-nodes [selectedNode]="selectedNode"></app-message-wait-nodes>

      <div class="p-3 -mt-6" *ngIf="this.selectedNode?.type === NodeType.OfferPartialPayment">
         <p-messages class="" severity="info" [closable]="true">
            <ng-template pTemplate>
               <div class="p-message flex flex-row gap-2">
                  <span class="pi pi-info-circle p-1"></span>
                  <span>
                     {{ 'DATA.MODULES.CAMPAINGS.ELEMENTS.NODES.PARAMETERS.PARTIAL_PAYMENT_PROPOSAL' | translate }}
                     <b> {{ selectedNode?.config?.['minimumAmount'].amount ?? '0' }} EUR </b>
                     {{ 'DATA.MODULES.CAMPAINGS.ELEMENTS.NODES.PARAMETERS.EXPIRATION_DAYS' | translate }}
                     <b
                        >{{ selectedNode?.config?.['expirationDays'] ?? 'N' }} {{ 'DATA.COMMON.TIME.DAYS' | translate }}
                     </b>
                  </span>
               </div>
            </ng-template>
         </p-messages>
      </div>

      <div class="p-3 -mt-6" *ngIf="this.selectedNode?.type === NodeType.Experiment">
         <p-messages class="" severity="info" [closable]="true">
            <ng-template pTemplate>
               <div class="p-message flex flex-row gap-2">
                  <span class="pi pi-info-circle p-1"></span>
                  <span
                     [ngStyle]="{ 'text-wrap-style': 'pretty' }"
                     [innerHTML]="
                        'DATA.MODULES.CAMPAINGS.ELEMENTS.NODES.PARAMETERS.EXPERIMENT'
                           | translate: { percentage: selectedNode?.config?.['percentage'] ?? '0' }
                     "
                  >
                  </span>
               </div>
            </ng-template>
         </p-messages>
      </div>

      <div class="p-3 -mt-6" *ngIf="this.selectedNode?.type === NodeType.OfferPaymentAgreement">
         <p-messages class="" severity="info" [closable]="true">
            <ng-template pTemplate>
               <div class="p-message flex flex-row gap-2">
                  <span class="pi pi-info-circle p-1"></span>
                  <span>
                     {{ 'DATA.MODULES.CAMPAINGS.ELEMENTS.NODES.PARAMETERS.PARTIAL_PAYMENT_PROPOSAL' | translate }}

                     <b
                        >{{ selectedNode?.config?.['numberOfInstalments'] ?? 'N' }}
                        {{ 'DATA.MODULES.CAMPAINGS.ELEMENTS.NODES.PARAMETERS.FEE' | translate }}
                     </b>
                     {{ 'DATA.MODULES.CAMPAINGS.ELEMENTS.NODES.PARAMETERS.EXPIRATION_DAYS' | translate }}
                     <b>
                        {{ selectedNode?.config?.['expirationDays'] ?? 'N' }}
                        {{
                           selectedNode?.config?.['expirationDays'] === 1
                              ? ('DATA.COMMON.TIME.DAY' | translate)
                              : ('DATA.COMMON.TIME.DAYS' | translate)
                        }}
                     </b>
                     <span [innerHTML]="'DATA.MODULES.CAMPAINGS.ELEMENTS.NODES.PARAMETERS.FROM_DAY' | translate"></span>
                     <b>
                        {{ selectedNode?.config?.['numberOfInstalments'] ?? 'N' }}
                        {{
                           selectedNode?.config?.['numberOfInstalments'] === 1
                              ? ('DATA.COMMON.TIME.MONTH' | translate)
                              : ('DATA.COMMON.TIME.MONTHS' | translate)
                        }}
                     </b>.
                     {{ 'DATA.MODULES.CAMPAINGS.ELEMENTS.NODES.PARAMETERS.EXACT_DAY' | translate }}
                  </span>
               </div>
            </ng-template>
         </p-messages>
      </div>
   </div>
</app-fieldset-p>
