<div *ngIf="installments.length && customerCase">
   <app-dynamic-form
      [formFields]="propostalForm"
      [formCols]="6"
      [submit]="false"
      [reset]="false"
      btnLabel3="{{ 'DATA.MODULES.SHARED.COMPONENTS.SEND_REMINDER.FORM.ACTIONS.CONFIRM' | translate }}"
      btnLabelSave="{{ 'DATA.MODULES.SHARED.COMPONENTS.SEND_REMINDER.FORM.ACTIONS.SEND' | translate }}"
      confirmMsg="{{ 'DATA.MODULES.SHARED.COMPONENTS.SEND_REMINDER.FORM.ACTIONS.CONFIRM_MESSAGE' | translate }}"
      (formValuesChanged)="onChanges($event)"
   />

   <div class="flex flex-column gap-2 p-3">
      <p
         [innerHTML]="
            'DATA.MODULES.SHARED.COMPONENTS.SEND_REMINDER.PAYMENT_PROPOSAL_EXPIRATION' | translate: { hours: 72 }
         "
      ></p>

      <p
         [innerHTML]="
            'DATA.MODULES.SHARED.COMPONENTS.SEND_REMINDER.DESCRIPTION_MSG'
               | translate
                  : {
                       customerName: customer.name,
                       totalAmount:
                          (customerCase.totalAmount.amount
                          | currencyWithCode: customerCase.totalAmount.currency || customerCase.totalAmountCurrency)
                    }
         "
      ></p>

      <p
         [innerHTML]="
            'DATA.MODULES.SHARED.COMPONENTS.SEND_REMINDER.FIRST_PAYMENT'
               | translate
                  : {
                       firstPayment: installments[0]?.amount?.amount
                          ? installments[0]?.amount?.amount / 100
                          : (customerCase.totalAmount.amount / nMonths
                            | currencyWithCode: customerCase.totalAmount.currency || customerCase.totalAmountCurrency),
                       nMonths: nMonths,
                       monthText: getMonthText(nMonths),
                       sms: sms,
                       email: email
                    }
         "
      ></p>

      <p>{{ 'DATA.MODULES.SHARED.COMPONENTS.SEND_REMINDER.EXACT_DATES' | translate }}</p>
   </div>

   <div *ngIf="installments.length" class="p-3">
      <p-table [value]="installments || []">
         <ng-template pTemplate="header">
            <tr>
               <th>
                  {{ 'DATA.MODULES.SHARED.COMPONENTS.SEND_REMINDER.INSTALLMENTS_TABLE_HEADER.INSTALLMENT' | translate }}
               </th>
               <th>
                  {{ 'DATA.MODULES.SHARED.COMPONENTS.SEND_REMINDER.INSTALLMENTS_TABLE_HEADER.AMOUNT' | translate }}
               </th>
            </tr>
         </ng-template>
         <ng-template pTemplate="body" let-instance let-rowIndex="rowIndex">
            <tr>
               <td>{{ rowIndex + 1 }}</td>
               <td>{{ instance?.amount?.amount | currencyWithCode: instance?.amount?.currency }}</td>
            </tr>
         </ng-template>
      </p-table>
   </div>

   <div class="flex flex-row-reverse">
      <p-button
         [disabled]="checkDisabled()"
         label="{{ 'DATA.MODULES.SHARED.COMPONENTS.SEND_REMINDER.FORM.ACTIONS.SEND' | translate }}"
         class="p-button-success"
         (onClick)="send()"
      />
   </div>
</div>
