import { Injectable } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { daysInWeek } from '@shared/helpers/variables';
import {
  NodeConfig,
  WaitDaysConfig,
  WaitDaysOfWeekConfig,
  WaitDaysForDueDateConfig,
  WaitDayOfMonthConfig,
  ConditionalConfig,
  OfferPartialPaymentConfig,
  OfferPaymentAgreementConfig,
  ConditionalSegmentConfig,
  MakeOutBoundCallConfig,
  ExperimentConfig,
} from '@modules/campaigns/Domain/Interfaces/INodeConfig';
import {
  FormCheckbox,
  FormDropdown,
  FormInputBase,
  FormNumber,
  FormTextbox,
} from '@shared/components/dynamic-form/models';
import { generatedaysOfMonth } from '@shared/helpers/variables';
import { maxSafeIntegerValidator } from '@shared/validators/forms/maxSafeInteger';
import { minElementsInArray } from '@shared/validators/forms/minElementsInArray';
import { generateVoiceDropdownOptions } from '@modules/campaigns/models/call-node.constants';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class NodeFormService {
  constructor(
    private fb: FormBuilder,
    private translate: TranslateService,
  ) {}

  dropdownOptions = generateVoiceDropdownOptions();

  public generateFormControls(
    config: NodeConfig,
    copies: any,
    campaigns: any,
    conditionalParentsNode: any,
    segments: any,
  ): FormInputBase<any>[] {
    const controls: FormInputBase<any>[] = [];
    //TODO: REFACTOR CASES TO SOMETHING ELSE
    if ('partDay' in config && 'waitDays' in config) {
      // Controls for WaitDaysConfig or WaitDaysForDueDateConfig
      controls.push(
        new FormDropdown({
          key: 'partDay',
          type: 'dropdown',
          required: false,
          label: this.translate.instant('DATA.MODULES.CAMPAINGS.WORKFLOWS.NODE_FORM.TIME_SLOT'),
          value: (config as WaitDaysConfig | WaitDaysForDueDateConfig).partDay,
          options: [
            {
              label: this.translate.instant(
                'DATA.MODULES.CAMPAINGS.WORKFLOWS.NODE_FORM.TIME_SLOT_MORNING',
              ),
              value: 'MORNING',
            },
            {
              label: this.translate.instant(
                'DATA.MODULES.CAMPAINGS.WORKFLOWS.NODE_FORM.TIME_SLOT_EVENING',
              ),
              value: 'EVENING',
            },
            {
              label: this.translate.instant(
                'DATA.MODULES.CAMPAINGS.WORKFLOWS.NODE_FORM.TIME_SLOT_FULL_DAY',
              ),
              value: 'FULL_DAY',
            },
          ],
          multi: false,
          filter: false,
          readonly: false,
        }),
      );

      controls.push(
        new FormNumber({
          key: 'waitDays',
          type: 'number',
          required: false,
          label: this.translate.instant('DATA.MODULES.CAMPAINGS.WORKFLOWS.NODE_FORM.WAIT_DAYS'),
          value: (config as WaitDaysConfig | WaitDaysForDueDateConfig).waitDays,
          placeholder: this.translate.instant(
            'DATA.MODULES.CAMPAINGS.WORKFLOWS.NODE_FORM.WAIT_DAYS_PLACEHOLDER',
          ),
          readonly: false,
        }),
      );
    }
    if ('dayOfWeek' in config) {
      // Controls for WaitDaysOfWeekConfig
      controls.push(
        new FormDropdown({
          key: 'partDay',
          type: 'dropdown',
          required: false,
          label: this.translate.instant('DATA.MODULES.CAMPAINGS.WORKFLOWS.NODE_FORM.TIME_SLOT'),
          value: (config as WaitDaysOfWeekConfig).partDay,
          options: [
            {
              label: this.translate.instant(
                'DATA.MODULES.CAMPAINGS.WORKFLOWS.NODE_FORM.TIME_SLOT_MORNING',
              ),
              value: 'MORNING',
            },
            {
              label: this.translate.instant(
                'DATA.MODULES.CAMPAINGS.WORKFLOWS.NODE_FORM.TIME_SLOT_EVENING',
              ),
              value: 'EVENING',
            },
            {
              label: this.translate.instant(
                'DATA.MODULES.CAMPAINGS.WORKFLOWS.NODE_FORM.TIME_SLOT_FULL_DAY',
              ),
              value: 'FULL_DAY',
            },
          ],
          multi: false,
          filter: false,
          readonly: false,
        }),
      );

      controls.push(
        new FormDropdown({
          key: 'dayOfWeek',
          type: 'dropdown',
          required: false,
          label: this.translate.instant('DATA.MODULES.CAMPAINGS.WORKFLOWS.NODE_FORM.DAY_OF_WEEK'),
          value: (config as WaitDaysOfWeekConfig).dayOfWeek,
          options: daysInWeek.map((day) => ({
            label: this.translate.instant(
              `DATA.MODULES.CAMPAINGS.WORKFLOWS.NODE_FORM.DAYS.${day.value.toUpperCase()}`,
            ),
            value: day.value,
          })),
          multi: false,
          filter: false,
          readonly: false,
        }),
      );
    }
    if ('dayOfMonth' in config) {
      // Controls for WaitDayOfMonthConfig
      controls.push(
        new FormDropdown({
          key: 'partDay',
          type: 'dropdown',
          required: false,
          label: this.translate.instant('DATA.MODULES.CAMPAINGS.WORKFLOWS.NODE_FORM.TIME_SLOT'),
          value: (config as WaitDayOfMonthConfig).partDay,
          options: [
            {
              label: this.translate.instant(
                'DATA.MODULES.CAMPAINGS.WORKFLOWS.NODE_FORM.TIME_SLOT_MORNING',
              ),
              value: 'MORNING',
            },
            {
              label: this.translate.instant(
                'DATA.MODULES.CAMPAINGS.WORKFLOWS.NODE_FORM.TIME_SLOT_EVENING',
              ),
              value: 'EVENING',
            },
            {
              label: this.translate.instant(
                'DATA.MODULES.CAMPAINGS.WORKFLOWS.NODE_FORM.TIME_SLOT_FULL_DAY',
              ),
              value: 'FULL_DAY',
            },
          ],
          multi: false,
          filter: false,
          readonly: false,
        }),
      );

      controls.push(
        new FormDropdown({
          key: 'dayOfMonth',
          type: 'dropdown',
          required: false,
          label: this.translate.instant('DATA.MODULES.CAMPAINGS.WORKFLOWS.NODE_FORM.DAY_OF_MONTH'),
          value: (config as WaitDayOfMonthConfig).dayOfMonth,
          options: generatedaysOfMonth(),
          multi: false,
          filter: false,
          readonly: false,
        }),
      );
    }
    if ('copyIds' in config) {
      // Controls for SMSConfig, SuperSMSConfig, etc.
      let valueDropdown: string[] = (config as any).copyIds as Array<string>;
      valueDropdown = valueDropdown ? valueDropdown : [];
      const isSuper = (config['super'] as boolean) || config['copyIds'].length > 1;
      controls.push(
        new FormDropdown({
          key: 'copyIds',
          type: 'dropdown',
          required: true,
          label: this.translate.instant('DATA.MODULES.CAMPAINGS.WORKFLOWS.NODE_FORM.TEMPLATES'),
          options: copies,
          multi: isSuper,
          filter: true,
          readonly: false,
          validators: isSuper ? [minElementsInArray(2)] : [],
          value: valueDropdown.length > 0 ? (isSuper ? valueDropdown : valueDropdown[0]) : null,
        }),
      );
    }

    if ('sendToAllContacts' in config) {
      const sendToAllContacts = Boolean(config['sendToAllContacts']);
      controls.push(
        new FormCheckbox({
          key: 'sendToAllContacts',
          label: this.translate.instant(
            'DATA.MODULES.CAMPAINGS.WORKFLOWS.NODE_FORM.SEND_ALL_CONTACTS',
          ),
          value: sendToAllContacts,
          type: 'boolean',
        }),
      );
    }

    if ('copyId' in config && 'voiceId' in config) {
      controls.push(
        new FormDropdown({
          key: 'copyId',
          type: 'text',
          required: true,
          options: [{ label: 'Playbook 1', value: '11e03515-de2a-4e3c-a091-07556779fe44' }],
          multi: false,
          label: this.translate.instant('DATA.MODULES.CAMPAINGS.WORKFLOWS.NODE_FORM.MESSAGE'),
          readonly: false,
          value: (config as MakeOutBoundCallConfig).copyId,
        }),
        new FormDropdown({
          key: 'voiceId',
          type: 'text',
          required: true,
          label: this.translate.instant('DATA.MODULES.CAMPAINGS.WORKFLOWS.NODE_FORM.VOICE'),
          options: this.dropdownOptions,
          multi: false,
          readonly: false,
          value: (config as MakeOutBoundCallConfig).voiceId,
        }),
      );
    }

    if ('targetNodeId' in config && 'statuses' in config) {
      // Controls for ConditionalConfig
      controls.push(
        new FormDropdown({
          key: 'status',
          type: 'dropdown',
          required: false,
          label: this.translate.instant('DATA.MODULES.CAMPAINGS.WORKFLOWS.NODE_FORM.STATUS'),
          value: (config as ConditionalConfig).statuses,
          options: [
            {
              value: 'received',
              label: this.translate.instant('DATA.MODULES.CAMPAINGS.WORKFLOWS.NODE_FORM.RECEIVED'),
            },
            {
              value: 'read',
              label: this.translate.instant('DATA.MODULES.CAMPAINGS.WORKFLOWS.NODE_FORM.READ'),
            },
            {
              value: 'error',
              label: this.translate.instant('DATA.MODULES.CAMPAINGS.WORKFLOWS.NODE_FORM.ERROR'),
            },
          ],
          multi: true,
          filter: false,
          readonly: false,
        }),
      );
    }
    if ('targetCampaignId' in config) {
      const valueDropdown: string = (config as any).targetCampaignId as string;
      controls.push(
        new FormDropdown({
          key: 'campañaId',
          type: 'dropdown',
          required: false,
          label: this.translate.instant(
            'DATA.MODULES.CAMPAINGS.WORKFLOWS.NODE_FORM.ASSIGN_CAMPAIGN',
          ),
          value: valueDropdown,
          options: campaigns,
          grouped: true,
          multi: false,
          filter: true,
          readonly: false,
        }),
      );
    }
    if ('antesODespues' in config) {
      // Controls for AssignCampaignConfig
      controls.push(
        new FormDropdown({
          key: 'antesODespues',
          type: 'dropdown',
          required: false,
          label: this.translate.instant('DATA.MODULES.CAMPAINGS.WORKFLOWS.NODE_FORM.BEFORE_AFTER'),
          value:
            (config as WaitDaysConfig | WaitDaysForDueDateConfig).waitDays !== null
              ? (config as WaitDaysConfig | WaitDaysForDueDateConfig).waitDays! > 0
                ? 'afterDueDate'
                : 'beforeDueDate'
              : null,

          options: [
            {
              label: this.translate.instant('DATA.MODULES.CAMPAINGS.WORKFLOWS.NODE_FORM.BEFORE'),
              value: 'beforeDueDate',
            },
            {
              label: this.translate.instant('DATA.MODULES.CAMPAINGS.WORKFLOWS.NODE_FORM.AFTER'),
              value: 'afterDueDate',
            },
          ],
          multi: false,
          filter: false,
          readonly: false,
        }),
      );
    }

    if ('numberOfInstalments' in config && 'expirationDays' in config) {
      controls.push(
        new FormDropdown({
          key: 'numberOfInstalments',
          type: 'number',
          required: true,
          label: this.translate.instant('DATA.MODULES.CAMPAINGS.WORKFLOWS.NODE_FORM.NUMBER_FEE'),
          value: (config as OfferPaymentAgreementConfig).numberOfInstalments,
          placeholder: '',
          multi: false,
          filter: false,
          readonly: false,
          options: Array.from({ length: 11 }, (_, i) => i + 2),
        }),
      );

      controls.push(
        new FormNumber({
          key: 'expirationDays',
          type: 'number',
          placeholder: this.translate.instant(
            'DATA.MODULES.CAMPAINGS.WORKFLOWS.NODE_FORM.DURATION_DAYS',
          ),
          required: true,
          label: this.translate.instant('DATA.MODULES.CAMPAINGS.WORKFLOWS.NODE_FORM.EXPIRATION'),
          value: (config as OfferPaymentAgreementConfig).expirationDays,
          readonly: false,
          disabled: false,
          validators: [Validators.min(1), Validators.max(90)],
        }),
      );
    }

    if ('segmentIds' in config) {
      const segmentIds = (config as ConditionalSegmentConfig).segmentIds || [];
      controls.push(
        new FormDropdown({
          key: 'segmentIds',
          type: 'dropdown',
          required: true,
          label: this.translate.instant('DATA.MODULES.CAMPAINGS.WORKFLOWS.NODE_FORM.SEGMENTS'),
          options: segments,
          multi: false,
          filter: false,
          readonly: false,
          value: segmentIds.length > 0 ? segmentIds[0] : null,
        }),
      );
    }

    if ('percentage' in config) {
      controls.push(
        new FormTextbox({
          key: 'percentage',
          type: 'number',
          required: true,
          label: this.translate.instant('DATA.MODULES.CAMPAINGS.WORKFLOWS.NODE_FORM.PERCENTAGE'),
          value: (config as ExperimentConfig).percentage?.toString(),
          placeholder: '',
          multi: false,
          filter: false,
          readonly: false,
          inputGroup: true,
          addon: '%',
          validators: [Validators.min(0), Validators.max(100)],
        }),
      );
    }

    if (
      'minimumAmount' in config &&
      'minimumPercentageAmount' in config &&
      'expirationDays' in config
    ) {
      controls.push(
        new FormNumber({
          key: 'amount',
          type: 'number',
          required: true,
          label: this.translate.instant(
            'DATA.MODULES.CAMPAINGS.WORKFLOWS.NODE_FORM.MINIMUM_AMOUNT',
          ),
          value: (config as OfferPartialPaymentConfig).minimumAmount.amount,
          placeholder: '',
          readonly: false,
          inputGroup: true,
          addon: 'EUR',
          validators: [maxSafeIntegerValidator()],
        }),
      );
      controls.push(
        new FormNumber({
          key: 'minimumPercentageAmount',
          type: 'number',
          required: false,
          label: this.translate.instant(
            'DATA.MODULES.CAMPAINGS.WORKFLOWS.NODE_FORM.MINIMUM_AMOUNT_TAN',
          ),
          value: (config as OfferPartialPaymentConfig).minimumPercentageAmount,
          placeholder: '',
          readonly: false,
          inputGroup: true,
          isVisible: false,
          addon: '%',
          validators: [Validators.pattern('^(\\d|[1-9]\\d|99)$'), Validators.min(1)],
        }),
      );

      controls.push(
        new FormNumber({
          key: 'expirationDays',
          type: 'number',
          placeholder: this.translate.instant(
            'DATA.MODULES.CAMPAINGS.WORKFLOWS.NODE_FORM.DURATION_DAYS',
          ),
          required: false,
          label: this.translate.instant('DATA.MODULES.CAMPAINGS.WORKFLOWS.NODE_FORM.EXPIRATION'),
          value: (config as OfferPartialPaymentConfig).expirationDays,
          readonly: false,
          disabled: false,
          validators: [Validators.min(1), Validators.max(90)],
        }),
      );
    }

    return controls;
  }
}
