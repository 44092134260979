import { gql } from 'apollo-angular';

export const ADD_USER = gql`
  mutation ($input: CreateUserInput!) {
    createUser(input: $input) {
      id
      email
      name
      surname
      language
      tenantId
      collectors {
        id
        name
      }
      role
      extraPermissions
    }
  }
`;

export const EDIT_USER = gql`
  mutation ($input: UpdateUserInput!) {
    updateUser(input: $input) {
      id
      email
      name
      surname
      language
      tenantId
      collectors {
        id
        name
      }
      role
      extraPermissions
    }
  }
`;

export const GET_USERS = gql`
  query ($tenantId: ID!) {
    users(tenantId: $tenantId) {
      id
      email
      name
      surname
      language
      tenantId
      collectors {
        id
        name
      }
      role
      extraPermissions
    }
  }
`;

export const GET_USER = gql`
  query ($id: ID!) {
    user(id: $id) {
      id
      email
      name
      surname
      language
      tenantId
      collectors {
        id
        name
      }
      role
      extraPermissions
    }
  }
`;

export const GET_USER_ROLES = gql`
  query {
    userRoles {
      id
      name
    }
  }
`;

export const DELETE_USER = gql`
  mutation ($id: ID!) {
    deleteUser(id: $id) {
      id
    }
  }
`;
