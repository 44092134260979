import { IWorkflowValidator } from '@modules/campaigns/Domain/Interfaces/IWorkflowValidator';
import { ValidationError } from '@modules/campaigns/Domain/Enums/ValidationErrors';
import { IWorkflow } from '@modules/campaigns/Domain/Interfaces/IWorkflow';
import { WorkflowType } from '@modules/campaigns/Domain/Enums/WorkflowType';

export class DuplicateNodeIDsValidator implements IWorkflowValidator {
  validate(workflow: IWorkflow): string[] {
    const errors: string[] = [];
    const nodeIds = workflow.nodes.map((node) => node.id);
    const uniqueNodeIds = new Set(nodeIds);

    if (uniqueNodeIds.size !== nodeIds.length) {
      errors.push('DUPLICATE_NODE_IDS');
    }

    return errors;
  }

  shouldValidate(workflow: IWorkflow): boolean {
    return workflow.type === WorkflowType.DRIP || workflow.type === WorkflowType.PILL;
  }
}
