<!-- Errors coming from required validation -->
<div class="mt-1 text-red-700" *ngIf="errors && errors['required']">
    {{ 'DATA.COMMON.FORM.ERROR.REQUIRED' | translate }}
</div>

<!-- Errors coming from email validation -->
<div class="mt-1 text-red-700" *ngIf="errors && (errors['email'] || errors['invalidEmails'])">
    {{ 'DATA.COMMON.FORM.ERROR.EMAIL' | translate }}
</div>

<!-- Errors coming from min length validation -->
<div class="mt-1 text-red-700" *ngIf="errors && errors['minlength']">
    {{ 'DATA.COMMON.FORM.ERROR.MIN_LENGTH' | translate: { requiredLength: errors['minlength'].requiredLength } }}
</div>

<!-- Errors coming from max length validation -->
<div class="mt-1 text-red-700" *ngIf="errors && errors['maxlength']">
    {{ 'DATA.COMMON.FORM.ERROR.MAX_LENGTH' | translate: { requiredLength: errors['maxlength'].requiredLength } }}
</div>

<!-- Errors coming from pattern validation -->
<div class="mt-1 text-red-700" *ngIf="errors && errors['pattern']">
    {{ 'DATA.COMMON.FORM.ERROR.PATTERN' | translate }}
</div>

<!-- Errors coming from min validation -->
<div class="mt-1 text-red-700" *ngIf="errors && errors['min']">
    {{ 'DATA.COMMON.FORM.ERROR.MIN' | translate: { min: errors['min'].min } }}
</div>

<!-- Errors coming from max validation -->
<div class="mt-1 text-red-700" *ngIf="errors && errors['max']">
    {{ 'DATA.COMMON.FORM.ERROR.MAX' | translate: { max: errors['max'].max } }}
</div>

<!-- Custom errors coming from API -->
<div class="mt-1 text-red-700" *ngIf="errors && errors['custom']">
    {{ errors['custom'] | translate }}
</div>

<!-- Error coming from empty values -->
<div class="mt-1 text-red-700" *ngIf="errors && errors['emptyValue']">
    {{ 'DATA.COMMON.FORM.ERROR.EMPTY_VALUE' | translate }}
</div>

<!-- Error coming from invalid DNI or NIF format -->
<div class="mt-1 text-red-700" *ngIf="errors && errors['invalidDniNif']">
    {{ 'DATA.COMMON.FORM.ERROR.INVALID_DNI_NIF' | translate }}
</div>

<!-- Error coming from invalid postal code format -->
<div class="mt-1 text-red-700" *ngIf="errors && errors['invalidCp']">
    {{ 'DATA.COMMON.FORM.ERROR.INVALID_CP' | translate }}
</div>

<!-- Error coming from exceeded max safe integer -->
<div class="mt-1 text-red-700" *ngIf="errors && errors['maxSafeInteger']">
    {{ 'DATA.COMMON.FORM.ERROR.MAX_SAFE_INTEGER' | translate }}
</div>

<!-- Error for invalid alias format -->
<div class="mt-1 text-red-700" *ngIf="errors && errors['invalidAlphaDigit']">
    {{ 'DATA.COMMON.FORM.ERROR.INVALID_ALPHA_DIGIT' | translate }}
</div>

<!-- Error for invalid IBAN -->
<div class="mt-1 text-red-700" *ngIf="errors && errors['iban']">
    {{ 'DATA.COMMON.FORM.ERROR.IBAN' | translate }}
</div>

<!-- Error for invalid BIC -->
<div class="mt-1 text-red-700" *ngIf="errors && errors['bic']">
    {{ 'DATA.COMMON.FORM.ERROR.BIC' | translate }}
</div>

<!-- Error for too many characters -->
<div class="mt-1 text-red-700" *ngIf="errors && errors['maxChar']">
    {{ 'DATA.COMMON.FORM.ERROR.MAX_CHAR' | translate: { maxChar: errors['maxChar'] } }}
</div>

<!-- Error for future date validation -->
<div class="mt-1 text-red-700" *ngIf="errors && errors['futureDate']">
    {{ 'DATA.COMMON.FORM.ERROR.FUTURE_DATE' | translate }}
</div>

<!-- Error for amount exceeding total -->
<div class="mt-1 text-red-700" *ngIf="errors && errors['maxImportOnTotalAmount']">
    {{ 'DATA.COMMON.FORM.ERROR.MAX_IMPORT_ON_TOTAL_AMOUNT' | translate }}
</div>

<!-- Error for insufficient elements in array -->
<div class="mt-1 text-red-700" *ngIf="errors && errors['minElementsInArray']">
    {{ 'DATA.COMMON.FORM.ERROR.MIN_ELEMENTS_IN_ARRAY' | translate: { amount: errors['minElementsInArray'].amount } }}
</div>

<!-- Error for start and end format -->
<div class="mt-1 text-red-700" *ngIf="errors && errors['startsWithAndEndWith']">
    {{ 'DATA.COMMON.FORM.ERROR.STARTS_WITH_AND_ENDS_WITH' | translate: { char: errors['startsWithAndEndWith'].char } }}
</div>

<!-- Error for invalid range -->
<div class="mt-1 text-red-700" *ngIf="errors && errors['rangeInvalid']">
    {{ 'DATA.COMMON.FORM.ERROR.RANGE_INVALID' | translate }}
</div>

<!-- Error for incomplete range -->
<div class="mt-1 text-red-700" *ngIf="errors && errors['rangeNotCompleted']">
    {{ 'DATA.COMMON.FORM.ERROR.RANGE_NOT_COMPLETED' | translate }}
</div>

<!-- Error for all options selected -->
<div class="mt-1 text-red-700" *ngIf="errors && errors['allOptionsSelected']">
    {{ 'DATA.COMMON.FORM.ERROR.ALL_OPTIONS_SELECTED' | translate }}
</div>
