import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { CurrentUserService } from '@core/services/currentUser.service';
import { LayoutService } from '../service/app.layout.service';
import { UserModel } from '@admin/modules/users/models/user.model';
import { TimezoneService } from '@shared/services/timezone.service';
import { DialogService } from 'primeng/dynamicdialog';
import { SelectorTimezoneComponent } from '@shared/components/selector-timezone/selector-timezone.component';
import { TranslateService } from '@ngx-translate/core';
import { PrimeNGConfig } from 'primeng/api';
import { CalendarTranslationService } from '@layout/profile-sidebar/Service/getCalendarTranslations.service';
import { UsersService } from '@admin/modules/users/services/users.service';

@Component({
  selector: 'app-profilemenu',
  templateUrl: './app.profilesidebar.component.html',
})
export class AppProfileSidebarComponent implements OnInit {
  _user: UserModel = {} as UserModel;
  selectedTimezone: string = '';
  oldSelectedTimezone: string = '';
  languages: any[] | undefined;
  selectedLanguage: any;

  constructor(
    public layoutService: LayoutService,
    private auth: AuthService,
    private router: Router,
    public user: CurrentUserService,
    private userService: UsersService,
    public timezoneService: TimezoneService,
    private dModal: DialogService,
    private translate: TranslateService,
    private primeNGConfig: PrimeNGConfig,
    private calendarTranslationService: CalendarTranslationService,
  ) {
    this._user = this.user.currentUser;
  }

  ngOnInit() {
    this.oldSelectedTimezone = this.timezoneService.getTimezone();
    this.languages = [
      { name: 'Español', code: 'es' },
      { name: 'English', code: 'en' },
    ];

    const savedLanguageCode = this.user.getCurrentLanguage();
    this.selectedLanguage = this.languages.find((lang) => lang.code === savedLanguageCode);
    this.translate.use(savedLanguageCode);

    this.updatePrimeNGLocale(savedLanguageCode as 'es' | 'en');
  }

  changeLanguage($event: any) {
    if ($event.value) {
      const newLanguageCode = $event.value.code;
      this.translate.use(newLanguageCode);

      this.userService.getUser(this._user.id).subscribe((userData: any) => {
        if (!userData) {
          console.error('Error updating language');
        }

        this.user
          .updateLanguageCurrentUser(newLanguageCode, userData.extraPermissions)
          .then(() => {
            localStorage.setItem('appLanguage', newLanguageCode);

            this.updatePrimeNGLocale(newLanguageCode);

            window.location.reload();
          })
          .catch((error) => {
            console.error('Error updating language:', error);
          });
      });
    }
  }

  private updatePrimeNGLocale(lang: 'en' | 'es') {
    const calendarTranslation = this.calendarTranslationService.getTranslation(lang);
    this.primeNGConfig.setTranslation(calendarTranslation);
  }

  get visible(): boolean {
    return this.layoutService.state.profileSidebarVisible;
  }

  set visible(_val: boolean) {
    this.layoutService.state.profileSidebarVisible = _val;
  }

  signOut() {
    localStorage.clear();
    setTimeout(() => {
      this.auth.logout({
        logoutParams: { returnTo: window.location.origin },
      });
    }, 300);
  }

  navigate(path: string) {
    this.router.navigate([path], {
      preserveFragment: true,
      queryParamsHandling: 'preserve',
    });

    this.layoutService.state.profileSidebarVisible = false;
  }

  showDialogTimezone() {
    const ref = this.dModal.open(SelectorTimezoneComponent, {
      header: 'Seleccionar un huso horario',
      width: '60vw',
      height: 'auto',
      closeOnEscape: true,
    });
    ref.onClose.subscribe();
  }
}
