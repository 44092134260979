import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

interface CampaignFilterConfig {
  allowedStatuses?: string[];
  allowedTypes?: string[];
  excludeIds?: string[];
  customFilter?: (campaign: any) => boolean;
}

@Injectable({
  providedIn: 'root',
})
export class GetGroupedCampaignsService {
  constructor(private translate: TranslateService) {}

  /**
   * Filters and groups campaigns based on configurable filters.
   * @param campaigns - List of all campaigns.
   * @param filterConfig - Object with filtering configurations.
   */
  getGroupedCampaigns(campaigns: any[], filterConfig: CampaignFilterConfig): any[] {
    let filteredCampaigns = campaigns;

    // Step 1: Apply status filter if defined
    if (filterConfig.allowedStatuses && filterConfig.allowedStatuses.length > 0) {
      filteredCampaigns = filteredCampaigns.filter((c) =>
        filterConfig.allowedStatuses!.includes(c.status),
      );
    }

    // Step 2: Apply type filter if defined
    if (filterConfig.allowedTypes && filterConfig.allowedTypes.length > 0) {
      filteredCampaigns = filteredCampaigns.filter((c) =>
        filterConfig.allowedTypes!.includes(c.type),
      );
    }

    // Step 3: Exclude specific campaign IDs
    if (filterConfig.excludeIds && filterConfig.excludeIds.length > 0) {
      filteredCampaigns = filteredCampaigns.filter((c) => !filterConfig.excludeIds!.includes(c.id));
    }

    // Step 4: Apply custom filter if provided
    if (filterConfig.customFilter) {
      filteredCampaigns = filteredCampaigns.filter(filterConfig.customFilter);
    }

    const mappedCampaigns = filteredCampaigns
      .map((campaign) => ({
        label: campaign.name,
        value: campaign.id,
        status: campaign.status,
        group: campaign.status,
      }))
      .sort((a, b) => a.label.localeCompare(b.label));

    const groupedMap = new Map<string, any>();

    mappedCampaigns.forEach((campaign) => {
      if (!groupedMap.has(campaign.status)) {
        groupedMap.set(campaign.status, {
          section: this.translate.instant('DATA.COMMON.STATUS.' + campaign.status),
          value: campaign.status,
          items: [],
        });
      }
      groupedMap.get(campaign.status).items.push({
        label: campaign.label,
        value: campaign.value,
        group: campaign.status,
      });
    });

    return Array.from(groupedMap.values());
  }
}
