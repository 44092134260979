import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { CreateNewNodesService } from '../../services/create-new-nodes.service';
import { NodeClass } from '@modules/campaigns/Domain/NodeClass';
import { NodeFactory } from '@modules/campaigns/Domain/Factories/NodeFactory';
import { WorkflowClass } from '@modules/campaigns/Domain/WorkflowClass';
import { draggableMenu, draggables } from '@shared/components/joint-js/config/constants';

@Component({
  selector: 'app-node-drag-drop',
  templateUrl: './node-drag-drop.component.html',
  styleUrls: ['./node-drag-drop.component.scss'],
})
export class NodeDragDropComponent implements OnInit {
  @Input() graph: any;
  @Input() workflow: WorkflowClass | undefined;
  @Output() nodeDrag = new EventEmitter<any>();
  draggables: draggableMenu = draggables;

  constructor(private createNewNodes: CreateNewNodesService) {}

  ngOnInit() {
    this.draggables = this.createNewNodes.translateDraggables(draggables);
  }

  drop(event: CdkDragDrop<string[]>) {
    const type = event.item.element.nativeElement.id;

    if (!type) {
      this.handleError('Node type is not defined.');
      return;
    }

    const localPoint = this.graph.paper!.clientToLocalPoint(event.dropPoint.x, event.dropPoint.y);

    try {
      const newNode = this.createNewNodes.createNode(type, localPoint.x, localPoint.y);
      if (!newNode) {
        this.handleError('Failed to create a new node.');
        return;
      }

      // DOMAIN LOGIC
      const newDNodeID = newNode?.attributes?.['data']?.original;
      const nodeType = NodeFactory.mapStringToNodeType(type);
      if (nodeType && newDNodeID) {
        const newDNode = NodeFactory.createNode(
          nodeType,
          localPoint.x,
          localPoint.y,
          newDNodeID.toString(),
        );

        if (newDNode && this.workflow) {
          newDNode.initNodeConfig();
          this.workflow.addNode(newDNode);
        } else {
          this.handleError('Error: Could not add node to workflow.');
        }
      } else {
        this.handleError('Error: Node type or ID is missing.');
      }

      this.graph.graph!.addCell(newNode);
      this.graph.selectedElement = newNode;
      this.nodeDrag.emit(newNode);
    } catch (error: unknown) {
      if (error instanceof Error) {
        this.handleError('An unexpected error occurred: ' + error.message);
      } else {
        this.handleError('An unexpected error occurred: ' + String(error));
      }
    }
  }

  private handleError(message: string) {
    console.error(message);
  }
}
