<p-sidebar
   [(visible)]="visible"
   position="right"
   [transitionOptions]="'.3s cubic-bezier(0, 0, 0.2, 1)'"
   styleClass="layout-profile-sidebar w-full sm:w-25rem"
>
   <div class="flex flex-column mx-auto md:mx-0">
      <div class="flex flex-row gap-1 justify-content-between">
         <div>
            <span class="font-semibold">{{ 'DATA.COMMON.PROFILE.HELLO' | translate }} </span>
            <span class="text-color-secondary font-medium">{{ _user.name }}</span>
         </div>
         <app-custom-tag [value]="_user.role.toUpperCase()" class="mb-3"></app-custom-tag>
      </div>
      <p-divider></p-divider>

      <ul class="list-none m-0 p-0">
         <li (click)="navigate('/profile')">
            <a
               class="cursor-pointer flex surface-border mb-3 p-3 align-items-center border-round hover:surface-hover transition-colors transition-duration-150"
            >
               <span>
                  <i class="pi pi-user text-xl text-primary"></i>
               </span>
               <div class="ml-3">
                  <span class="mb-2 font-semibold">{{ 'DATA.COMMON.USER_MENU.PROFILE' | translate }}</span>
               </div>
            </a>
         </li>

         <li (click)="showDialogTimezone()">
            <a
               class="cursor-pointer flex surface-border mb-3 p-3 align-items-center border-round hover:surface-hover transition-colors transition-duration-150"
            >
               <span>
                  <i class="pi pi-hourglass text-xl text-primary"></i>
               </span>
               <div class="ml-3">
                  <span class="mb-2 font-semibold">{{ oldSelectedTimezone }}</span>
               </div>
            </a>
         </li>

         <li>
            <a
               [ngStyle]="{ 'max-height': '42.5px' }"
               class="cursor-pointer flex surface-border mb-3 p-3 flex-row justify-content-between align-items-center border-round hover:surface-hover transition-colors transition-duration-150"
            >
               <div class="flex">
                  <span>
                     <i class="pi pi-language text-xl text-primary"></i>
                  </span>
                  <div class="ml-3">
                     <span class="mb-2 font-semibold">{{ 'DATA.COMMON.USER_MENU.LANGUAGE' | translate }}</span>
                  </div>
               </div>
               <div>
                  <p-dropdown
                     [options]="languages"
                     [(ngModel)]="selectedLanguage"
                     (onChange)="changeLanguage($event)"
                     optionLabel="name"
                     placeholder="Lenguage"
                  />
               </div>
            </a>
         </li>

         <li (click)="signOut()">
            <a
               class="cursor-pointer flex surface-border mb-3 p-3 align-items-center border-round hover:surface-hover transition-colors transition-duration-150"
            >
               <span>
                  <i class="pi pi-power-off text-xl text-primary"></i>
               </span>
               <div class="ml-3">
                  <span class="mb-2 font-semibold">{{ 'DATA.COMMON.USER_MENU.LOG_OUT' | translate }}</span>
               </div>
            </a>
         </li>
      </ul>
      <p-divider></p-divider>
   </div>
</p-sidebar>
