import { AbstractControl, ValidatorFn } from '@angular/forms';

function startsAndEndsWith(symbol: any, value: string) {
  return value.charAt(0) === symbol && value.charAt(value.length - 1) === symbol;
}

export function startsWithAndEndWith(char: string): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const value = control.value;

    if (!value) return null;

    if (value.length < 2) {
      return { startsWithAndEndsWith: { char } };
    }

    if (!startsAndEndsWith(char, value)) {
      return { startsWithAndEndWith: { char } };
    }

    return null;
  };
}
