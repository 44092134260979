import { Component, Input, OnInit } from '@angular/core';
import { NodeClass } from '@modules/campaigns/Domain/NodeClass';
import { NodeType } from '@modules/campaigns/Domain/Enums/NodeType';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-message-wait-nodes',
  templateUrl: './message-wait-nodes.component.html',
  styleUrls: ['./message-wait-nodes.component.scss'],
})
export class MessageWaitNodesComponent implements OnInit {
  @Input() selectedNode?: NodeClass;

  timeOfWeekTranslations: { [key: string]: string } = {};
  private daysOfWeekTranslations: { [key: string]: string } = {};

  protected readonly NodeType = NodeType;

  constructor(private translate: TranslateService) {}

  ngOnInit(): void {
    this.loadTranslations();
  }
  private loadTranslations(): void {
    this.translate
      .get('DATA.COMMON.TIME_OF_WEEK')
      .subscribe((translations: { [key: string]: string }) => {
        this.timeOfWeekTranslations = translations;
      });

    this.translate
      .get('DATA.COMMON.DAYS_OF_WEEK')
      .subscribe((translations: { [key: string]: string }) => {
        this.daysOfWeekTranslations = translations;
      });
  }

  translateTime(day: string): string {
    return this.timeOfWeekTranslations[day] || day;
  }

  translateDay(day: string): string {
    return this.daysOfWeekTranslations[day] || day;
  }

  getPartDay(): string {
    return this.translateTime(this.selectedNode?.config?.['partDay'] || '-');
  }

  getWaitDays(): string {
    return this.selectedNode?.config?.['waitDays'] ?? '0';
  }

  getDayOfWeek(): string {
    return this.translateDay(this.selectedNode?.config?.['dayOfWeek'] || '-');
  }

  getDayOfMonth(): string {
    const dayOfMonth = this.selectedNode?.config?.['dayOfMonth'];
    return dayOfMonth === 'last'
      ? this.translate.instant('DATA.COMMON.LAST_DAY')
      : dayOfMonth || '-';
  }
}
